import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorMessage, successMessage } from "redux/actions/notification";
import { fetchSettingsData } from "redux/actions/smartCheckInstance";
import CustomSpinner from "shared/CustomSpinner/CustomSpinner";
import ChangePlanModal from "./ChangePlanModal/ChangePlanModal";
import CTAButton from "./CTAButton";
import "./settings.scss";

const Settings = ({ fetchingCurrencies, currencies }) => {
  const [submitStatus, setSubmitStatus] = useState(null);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const {
    updatingSettingsData,
    updateSettingsDataPayload,
    updateSettingsDataError,
    fetchingSettingsData,
    settingsDataPayload,
  } = useSelector(({ smartCheck }) => smartCheck);

  useEffect(() => {
    dispatch(fetchSettingsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!updatingSettingsData && updateSettingsDataPayload && submitStatus) {
      setSubmitStatus(false);
      setShow(false);
      dispatch(fetchSettingsData());
      dispatch(successMessage("Instance configuration successful"));
    }
    if (!updatingSettingsData && updateSettingsDataError && submitStatus) {
      setSubmitStatus(false);
      dispatch(
        errorMessage(
          updateSettingsDataError?.message ||
            "Unable to update default currency"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSettingsDataPayload, updatingSettingsData]);

  return (
    <div className="row fade_in sc-settings">
      <div className="col-12">
        <p className="fs-5 fw-bold">Subscription Plans</p>
        {fetchingSettingsData ? (
          <CustomSpinner height="100px" />
        ) : (
          <div className="row align-items-start align-items-md-center fade_in">
            <div className="col-12 fade_in">
              <p className="text-secondary mb-4">
                Simple Pricing. No Hidden Fees. Advanced Features
              </p>

              <div className="col align-items-stretch mb-4">
                {settingsDataPayload?.data.data &&
                  Object.keys(settingsDataPayload.data.data).map((planKey) => (
                    <div key={planKey}>
                      <h4>{planKey}</h4>
                      <div className="row align-items-stretch mb-4">
                        {settingsDataPayload?.data.data[planKey].map((plan) => (
                          <div
                            className="col-md-6 col-lg-3 mb-2"
                            key={plan.ItemID}>
                            <div
                              className={`wrap ${
                                plan.current_plan ? "active" : ""
                              } ${plan.popular ? "popular" : ""}`}>
                              <div className="row mb-4">
                                <div className="col">
                                  <i className="ri-user-search-line text-primary"></i>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <h6 className="variant-body1 d-flex align-items-center gap-2">
                                    {plan.ItemName}{" "}
                                    <i className="ri-information-line text-muted"></i>
                                  </h6>
                                  <p className="variant-caption mt-0 mb-4">
                                    {plan.ItemDescription}
                                  </p>
                                  <h2
                                    className={`variant-title font-weight-bold mb-4 `}>
                                    ${plan.Price}
                                    <span className="variant-caption">
                                      /month
                                    </span>
                                  </h2>

                                  <CTAButton
                                    title={plan.PlanName}
                                    current={plan.current_plan}
                                    onClick={() =>
                                      !plan.current_plan && setShow(true)
                                    }
                                  />

                                  <div className="mb-4">
                                    {/* {plan.billable_items.map((item) => (
                                      <div className="d-flex align-items-center gap-2 mb-3">
                                        <i className="ri-check-line text-success"></i>
                                        <p className="variant-caption my-0">
                                          {item.name} - ${item.price}
                                        </p>
                                      </div>
                                    ))} */}
                                  </div>
                                </div>
                              </div>
                              {plan.popular && (
                                <div className="chip-popular">Popular</div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <ChangePlanModal show={show} toggleModal={() => setShow(!show)} />
    </div>
  );
};

export default Settings;
