import {
  accountService,
  serviceManager,
  smartCheckUrl,
} from "apiServices/config.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import {
  Get,
  GetFile,
  Patch,
  Post,
  Put,
} from "../../apiServices/apiHandler.service";

export const instanceActionTypes = {
  GET_ANALYTICS_COUNT: "[ SMART CHECK - INSTANCES] GET_ANALYTICS_COUNT",
  GET_ENROLMENT_RATE: "[ SMART CHECK - INSTANCES] GET_ENROLMENT_RATE",
  GET_FUND_BALANCE: "[ SMART CHECK - INSTANCES] GET_FUND_BALANCE",

  CHECK_MERCHANT_STATUS: "[ SMART CHECK - INSTANCES] CHECK_MERCHANT_STATUS",
  FETCH_TRANSACTIONS: "[ SMART CHECK - INSTANCES] FETCH_TRANSACTIONS",
  FETCH_TRANSACTIONS_DETAILS:
    "[ SMART CHECK - INSTANCES] FETCH_TRANSACTIONS_DETAILS",
  FETCH_CUSTOMER_ENROLLMENTS:
    "[ SMART CHECK - INSTANCES] FETCH_CUSTOMER_ENROLLMENTS",
  FETCH_CUSTOMER_ENROLMENT_DETAILS:
    "[ SMART CHECK - INSTANCES] FETCH_CUSTOMER_ENROLMENT_DETAILS",
  FETCH_BUSINESS_ENROLLMENTS:
    "[ SMART CHECK - INSTANCES] FETCH_BUSINESS_ENROLLMENTS",
  FETCH_LINKS: "[ SMART CHECK - INSTANCES] FETCH_LINKS",
  FETCH_PLANS: "[ SMART CHECK - INSTANCES] FETCH_PLANS",
  ADD_FUNDS: "[ SMART CHECK - INSTANCES] ADD_FUNDS",

  GET_INSTANCES: "[ SMART CHECK - INSTANCES] GET_INSTANCES",
  GET_COUNTRIES: "[ SMART CHECK - INSTANCES] GET_COUNTRIES",
  GET_CURRENCIES: "[ SMART CHECK - INSTANCES] GET_CURRENCIES",
  GET_KYC_HISTORY: "[ SMART CHECK - INSTANCES] GET_KYC_HISTORY",
  GET_ACCOUNTS: "[ SMART CHECK - INSTANCES] GET_ACCOUNTS",
  CREATE_INSTANCE: "[ SMART CHECK - INSTANCES] CREATE_INSTANCE",
  RESET_CREATE_INSTANCE: "[ SMART CHECK - INSTANCES] RESET_CREATE_INSTANCE",
  EDIT_INSTANCE: "[ SMART CHECK - INSTANCES] EDIT_INSTANCE",
  TOGGLE_STATUS: "[ SMART CHECK - INSTANCES] TOGGLE_STATUS",
  GET_KYC_SUMMARY: "[ SMART CHECK - INSTANCES] GET_KYC_SUMMARY",
  GET_CHART_DATA: "[ SMART CHECK - INSTANCES] GET_CHART_DATA",
  GET_COUNTRY_KYCS: "[ SMART CHECK - INSTANCES] GET_COUNTRY_KYCS",
  FETCH_KYC_COUNTRIES: "[ SMART CHECK - INSTANCES] FETCH_KYC_COUNTRIES",
  REQUEST_ACCESS: "[ SMART CHECK - INSTANCES] REQUEST_ACCESS",
  REPORTS: "[ SMART CHECK - INSTANCES] REPORTS",
  GET_VALIDATE_IDENTITY_TYPES:
    "[ SMART CHECK - INSTANCES] GET_VALIDATE_IDENTITY_TYPES",
  VALIDATE_IDENTITY: "VALIDATE_IDENTITY",
  VALIDATE_IDENTITY_DATA: "VALIDATE_IDENTITY_DATA",
  COSTS: "[ SMART CHECK - INSTANCES] COSTS",
  API_LOGS: "[ SMART CHECK - INSTANCES] API_LOGS",
  FETCH_SETTINGS_DATA: "[ SMART CHECK - INSTANCES] FETCH_SETTINGS_DATA",
  UPDATE_SETTINGS_DATA: "[ SMART CHECK - INSTANCES] UPDATE_SETTINGS_DATA",
  FETCH_SUPPORTED_CURRENCIES:
    "[ SMART CHECK - INSTANCES] FETCH_SUPPORTED_CURRENCIES",
  DOWNLOAD_REPORT: "DOWNLOAD_REPORT",
};

export const fetchTransactions = createAsyncAction(
  instanceActionTypes.FETCH_TRANSACTIONS,
  async () => {
    return await Get(`v1/client-api/transactions-list`);
  }
);

export const fetchTransactionDetails = createAsyncAction(
  instanceActionTypes.FETCH_TRANSACTIONS_DETAILS,
  async (id) => {
    return await Get(`v1/client-api/transaction-detail/${id}}`);
  }
);

export const fetchCustomers = createAsyncAction(
  instanceActionTypes.FETCH_CUSTOMER_ENROLLMENTS,
  async () => {
    return await Get(`v1/client-api/customers-list`);
  }
);

export const fetchCustomerDetails = createAsyncAction(
  instanceActionTypes.FETCH_CUSTOMER_ENROLMENT_DETAILS,
  async (id) => {
    return await Get(`v1/client-api/customer-detail/${id}`);
  }
);

export const FetchBusinesses = createAsyncAction(
  instanceActionTypes.FETCH_BUSINESS_ENROLLMENTS,
  async () => {
    return await Get(`v1/business-enrollments/`);
  }
);

export const FetchLinks = createAsyncAction(
  instanceActionTypes.FETCH_LINKS,
  async () => {
    return await Get(`v1/smart-check/links/`);
  }
);

export const FetchPlans = createAsyncAction(
  instanceActionTypes.FETCH_PLANS,
  async () => {
    return await Get(`v1/smart-check/plans/`);
  }
);

export const fetchSupportedCurrencies = createAsyncAction(
  instanceActionTypes.FETCH_SUPPORTED_CURRENCIES,
  async () => {
    return await Get(`v1/corporateWallet/wallets/client`, accountService);
  }
);

export const makeTransfer = createAsyncAction(
  instanceActionTypes.ADD_FUNDS,
  async (payload) => {
    return await Post(
      `v1/corporateWallet/wallets/client`,
      payload,
      accountService
    );
  }
);

export const checkOnboardingStatus = createAsyncAction(
  instanceActionTypes.CHECK_MERCHANT_STATUS,
  async (Client_Identifier) => {
    const payload = {
      merchant_global_id: Client_Identifier,
    };
    return await Post(`v1/client-api/get-onboarding-status`, payload);
  }
);

export const requestAccess = createAsyncAction(
  instanceActionTypes.REQUEST_ACCESS,
  async (Client_Identifier, email) => {
    const payload = {
      merchant_global_id: Client_Identifier,
      email: email,
    };
    return await Post(`v1/client-api/onboard`, payload);
  }
);

export const fetchCosts = createAsyncAction(
  instanceActionTypes.COSTS,
  async ({ currency, startDate, endDate, search }) => {
    return await Get(
      `v1/kycrecords/kycredailyrecords?CurrencyCode=${currency}${
        startDate ? "&StartDate=" + startDate : ""
      }${endDate ? "&EndDate=" + endDate : ""}${
        search ? "&Search=" + search : ""
      }`
    );
  }
);

export const fetchApiLogs = createAsyncAction(
  instanceActionTypes.API_LOGS,
  async ({ currency, startDate, endDate, search, pageSize, pageNumber }) => {
    return await Get(
      `v1/kycrecords/kycrecords?CurrencyCode=${currency}${
        pageSize ? "&PageSize=" + pageSize : ""
      }${pageNumber ? "&PageNumber=" + pageNumber : ""}${
        startDate ? "&StartDate=" + startDate : ""
      }${endDate ? "&EndDate=" + endDate : ""}${
        search ? "&Keyword=" + search : ""
      }`
    );
  }
);

export const fetchSettingsData = createAsyncAction(
  instanceActionTypes.FETCH_SETTINGS_DATA,
  async () => {
    return await Get(`v1/client-api/get-subscriptions`);
  }
);

export const updateSettingsData = createAsyncAction(
  instanceActionTypes.UPDATE_SETTINGS_DATA,
  async (data) => {
    return await Put(`v1/kycsettings/settings`, { ...data });
  }
);

export const LoadSmartCheckInstances = createAsyncAction(
  instanceActionTypes.GET_INSTANCES,
  async ({ pageNo, pageSize, searchBy }) => {
    return await Get(
      `v1/kycsettings/getinstances${pageNo ? "?PageNumber=" + pageNo : ""}${
        pageSize ? "&MaxItem=" + pageSize : ""
      }${searchBy ? "&SearchBy=" + searchBy : ""}`
    );
  }
);

export const LoadCountryKYCs = createAsyncAction(
  instanceActionTypes.GET_COUNTRY_KYCS,
  async ({ countryCode }) => {
    return await Get(
      `v1/KYCConfiguration/getbycountrycode/${countryCode}`,
      serviceManager
    );
  }
);

export const fetchKyCCountries = createAsyncAction(
  instanceActionTypes.FETCH_KYC_COUNTRIES,
  async () => {
    return await Get(`v1/kycsettings/getkyccountries`);
  }
);

export const LoadServiceManagerCountries = createAsyncAction(
  instanceActionTypes.GET_COUNTRIES,
  async () => {
    return await Get(
      "v1/Country/GetAll?pageSize=1000&pageNumber=1",
      serviceManager
    );
  }
);

export const LoadServiceManagerCurrencies = createAsyncAction(
  instanceActionTypes.GET_CURRENCIES,
  async () => {
    return await Get(
      "v1/Currency/GetAll?pageSize=1000&pageNumber=1",
      serviceManager
    );
  }
);

export const LoadKycHistory = createAsyncAction(
  instanceActionTypes.GET_KYC_HISTORY,
  async ({ instanceId }) => {
    return await Get("v1/walletHistory.json");
  }
);
export const ConfigureSmartCheckInstance = createAsyncAction(
  instanceActionTypes.CREATE_INSTANCE,
  async (data, instanceId) => {
    const customHeader = instanceId ? { InstanceId: instanceId } : {};

    return instanceId
      ? await Put(`v1/kycsettings/update/instance`, data, "", customHeader)
      : await Post(`v1/kycsettings/create/instance`, data, "", customHeader);
  }
);

export const editKycInstance = createAsyncAction(
  instanceActionTypes.EDIT_INSTANCE,
  async (data) => {
    return await Patch("v1/account/instances", data);
  }
);
export const toggleStatus = createAsyncAction(
  instanceActionTypes.TOGGLE_STATUS,
  async ({ kycId }) => {
    return await Put(`v1/kycsettings/toggleinstance?id=${kycId}`, {}, null);
  }
);
export const LoadAccounts = createAsyncAction(
  instanceActionTypes.GET_ACCOUNTS,
  async ({ instanceId }) => {
    return await Get("v1/account/getaccounts", { InstanceId: instanceId });
  }
);
export const LoadKycSummary = createAsyncAction(
  instanceActionTypes.GET_KYC_SUMMARY,
  async ({ instanceId }) => {
    return await Get("v1/settings/instance/detail", { InstanceId: instanceId });
  }
);
export const LoadChartData = createAsyncAction(
  instanceActionTypes.GET_CHART_DATA,
  async () => {
    return await Get("chartData.json");
  }
);

export const validateIdentity = createAsyncAction(
  instanceActionTypes.VALIDATE_IDENTITY,
  async (identityType, countryCode, payload) => {
    // return Post(
    //   `v1/validate/${identityType?.toLowerCase()}`,
    //   payload
    // );
    return Post(
      `v1/${countryCode}/${identityType.toLowerCase()}-full`,
      payload,
      "https://vggkycservicetest.azurewebsites.net"
    );
  }
);
export const getValidateIdentityTypes = createAsyncAction(
  instanceActionTypes.GET_VALIDATE_IDENTITY_TYPES,
  async () => {
    return Get("v1/validate/IdentityTypes");
  }
);

export const getIdentityTypesData = createAsyncAction(
  instanceActionTypes.VALIDATE_IDENTITY_DATA,
  async (identityType) => {
    return Get(`v1/validate/identityTypes/properties/${identityType}`);
  }
);
export const downloadReport = createAsyncAction(
  instanceActionTypes.DOWNLOAD_REPORT,
  async (start, end) => {
    return GetFile(`v1/reports?StartDate=${start}&EndDate=${end}`);
  }
);
export const ResetConfiguration = createAsyncAction(
  instanceActionTypes.RESET_CREATE_INSTANCE,
  async () => {}
);

// SmartCheck OverView
export const getAnalyticsCount = createAsyncAction(
  instanceActionTypes.GET_ANALYTICS_COUNT,
  async () => {
    return await Get(`v1/client-api/analytics-count`);
  }
);

export const getEnrollmentRate = createAsyncAction(
  instanceActionTypes.GET_ENROLMENT_RATE,
  async () => {
    return await Get(`v1/client-api/enrolment-graph`);
  }
);

export const getFundBalance = createAsyncAction(
  instanceActionTypes.GET_FUND_BALANCE,
  async () => {
    return await Get(`v1/client-api/account-balance`);
  }
);
