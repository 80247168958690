import BackButton from "components/BackButton/BackButton";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import URLS from "helpers/urls";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DocumentItem from "./DocumentItem/DocumentItem";
import DocumentInfoModal from "./DocumentInfoModal/DocumentInfoModal";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import "./customerInfo.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerDetails } from "redux/actions/smartCheckInstance";

const breadcrumbItems = (name) => [
  {
    id: 1,
    name: "Customer Enrollment",
    path: URLS.CustomerEnrollment,
  },
  {
    id: 2,
    name,
    path: "",
  },
];

const CustomerInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const { customersDetails, fetchingCustomersDetails } = useSelector(
    ({ smartCheck }) => smartCheck
  );

  useEffect(() => {
    setDetails(customersDetails?.data);
  }, [customersDetails]);

  useEffect(() => {
    dispatch(fetchCustomerDetails(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [viewDetails, setViewDetails] = useState(false);
  return (
    <div className="service-details customer-info mb-5">
      <BackButton
        label="Go Back"
        onClick={() => {
          const url = history.location.pathname.includes("customer")
            ? URLS.CustomerEnrollment
            : URLS.BusinessEnrollment;
          history.push(url, { isBack: true });
        }}
      />

      <div className="my-3">
        <Breadcrumb items={breadcrumbItems("Adeboye Johnson")} />
      </div>

      {!fetchingCustomersDetails && details ? (
        <div className="table-wrapper customer-info">
          <div className="border-bottom p-3">
            <h4 className="page-title">Customer Information</h4>
          </div>
          <div className="row m-auto w-100">
            <div className="col-md-6 col-lg-4 border-r">
              <div className="p-3">
                <div className="sp-mb--24 sp-mt--24 d-flex align-items-center justify-content-center">
                  {details && <PersonalInfo data={details} />}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-8 py-2">
              <div className="p-3 document-container">
                <div className="row">
                  {details?.Documents?.map((item, index) => (
                    <div className="col-md-6 mb-4" key={index}>
                      <DocumentItem onClick={(data) => setViewDetails(true)} />
                    </div>
                  ))}
                  {details?.Documents?.length === 0 && (
                    <p className="w-100 text-center">No documents yet!</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center w-100">Loading....</p>
      )}
      <DocumentInfoModal
        show={viewDetails}
        toggleModal={() => setViewDetails(!viewDetails)}
      />
    </div>
  );
};

export default CustomerInfo;
