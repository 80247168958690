import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./drawer.scss";

const Drawer = ({ open, toggle, title, children, footerComponent }) => {
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className="drawer fadeInRight w-500 animated ml-auto"
    >
      <ModalHeader className="border-bottom" toggle={toggle}>
        <div>{title}</div>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      {footerComponent && <ModalFooter>{footerComponent}</ModalFooter>}
    </Modal>
  );
};

export default Drawer;
