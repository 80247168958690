import { ReactComponent as SuccessIcon } from "assets/images/successDot.svg";
import { ReactComponent as ErrorDot } from "assets/images/errorDot.svg";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import EmptyState from "components/emptyState/EmptyState";
import { LoadingTableContent } from "components/loader/Loader";
import _debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "redux/actions/smartCheckInstance";
// import TablePagination from "shared/tablePagination/tablePagination";
import Table from "vgg-ui-react/dist/components/Core/Table";
import ViewTransaction from "./ViewTransaction/ViewTransaction";
import "./transactions.scss";

const Transactions = () => {
  const dispatch = useDispatch();
  const [view, SetView] = useState({ open: false, data: null });
  const { transactions, fetchingTransactions } = useSelector(
    ({ smartCheck }) => smartCheck
  );
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(fetchTransactions({ ...page }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSearch = _debounce((event) => {
    const search = event.target.value;
    dispatch(fetchTransactions({ ...page, search }));
  }, 1000);

  const sortedTransactions = [...(transactions?.data || [])].sort((a, b) => {
    const dateA = new Date(a.CreatedAt);
    const dateB = new Date(b.CreatedAt);
    return dateB - dateA;
  });

  return (
    <div className="table-wrapper sc-transactions">
      <div className="border-bottom p-3">
        <h4 className="page-title">Transactions</h4>
      </div>
      <div className="p-3">
        <div className="sp-mb--24 sp-mt--24 d-flex align-items-center justify-content-between">
          <div>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search"
                className="form-control"
                onChange={handleSearch}
              />
              <i className="ri-search-line"></i>
            </div>
          </div>
          <FilterDropdown />
        </div>
        <Table cellVariants="default" className="table-striped">
          <thead>
            <tr>
              <th width="20%">Transaction Reference</th>
              <th width="20%">Amount</th>
              <th width="15%">New Balance</th>
              <th width="15%">Account</th>
              <th width="10%">Status</th>
              <th width="10%">Date</th>
              <th width="10%"></th>
            </tr>
          </thead>
          {fetchingTransactions ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={6} />
            </tbody>
          ) : (
            <tbody style={{ borderTop: "none" }}>
              {sortedTransactions &&
                sortedTransactions?.map((val, index) => (
                  <tr key={index}>
                    <td>{val?.TransactionRef || "--"}</td>
                    <td>{val?.Amount || "--"}</td>
                    <td>{val?.Balance || "--"}</td>
                    <td>{val?.AccountType || "--"}</td>
                    <td>
                      {val?.Status === "success" ? (
                        <SuccessIcon />
                      ) : (
                        <ErrorDot />
                      )}
                      {val?.Status || "--"}
                    </td>
                    <td>
                      {new Date(val?.CreatedAt).toLocaleDateString() || "--"}
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <button
                          className="btn btn-link btn-sm px-0 d-flex align-items-center variant-btn-text-primary"
                          onClick={() => SetView({ open: true, data: val })}>
                          View
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      </div>
      {(!fetchingTransactions && transactions?.data?.length < 1) ||
      !transactions?.data ? (
        <EmptyState />
      ) : null}
      {/* {transactions?.data && transactions?.data?.length > 0 && (
        <div className="px-3">
          <TablePagination
            data={transactions?.data}
            totalCount={transactions?.count}
            currentPage={transactions?.page_number}
            pageSize={transactions?.page_size}
            onPageChange={(page) =>
              setPage((prev) => ({ ...prev, pageNumber: page }))
            }
            totalPages={transactions?.pages}
          />
        </div>
      )} */}

      <ViewTransaction
        open={view.open}
        toggle={() => SetView({ open: false, data: null })}
        data={view.data}
      />
    </div>
  );
};

export default Transactions;
