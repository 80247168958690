import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import EmptyState from "components/emptyState/EmptyState";
import { LoadingTableContent } from "components/loader/Loader";
import { ViewCustomerInfo } from "helpers/urls";
import _debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchCustomers } from "redux/actions/smartCheckInstance";
import Table from "vgg-ui-react/dist/components/Core/Table";
import "./customerEnrollment.scss";

const CustomerEnrollment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { customers, fetchingCustomers } = useSelector(
    ({ smartCheck }) => smartCheck
  );
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(fetchCustomers({ ...page }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSearch = _debounce((event) => {
    const search = event.target.value;
    dispatch(fetchCustomers({ ...page, search }));
  }, 1000);

  console.log(customers, "customers");

  return (
    <div className="table-wrapper">
      <div className="border-bottom p-3">
        <h4 className="page-title">Transactions</h4>
      </div>
      <div className="p-3">
        <div className="sp-mb--24 sp-mt--24 d-flex align-items-center justify-content-between">
          <div>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search"
                className="form-control"
                onChange={handleSearch}
              />
              <i className="ri-search-line"></i>
            </div>
          </div>
          <FilterDropdown />
        </div>
        <Table cellVariants="default" className="table-striped">
          <thead>
            <tr>
              <th width="20%">Name</th>
              <th width="20%">Email Address</th>
              <th width="20%">Customer ID</th>
              <th width="15%">Country</th>
              <th width="15%">Enrollment Date</th>
              <th width="10%"></th>
            </tr>
          </thead>
          {fetchingCustomers ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={6} />
            </tbody>
          ) : (
            <tbody style={{ borderTop: "none" }}>
              {customers?.data?.data &&
                customers?.data?.data?.map((val, index) => (
                  <tr key={index}>
                    <td>
                      {val?.fullName && val.fullName.trim() !== ""
                        ? val.fullName
                        : "--"}
                    </td>
                    <td>{"--"}</td>
                    <td>{val?.CustomerID || "--"}</td>
                    <td>{"--"}</td>
                    <td>{new Date(val?.EnrolmentDate).toLocaleDateString()}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <button
                          className="btn btn-link btn-sm px-0 d-flex align-items-center variant-btn-text-primary"
                          onClick={() =>
                            history.push(ViewCustomerInfo(val.CustomerID))
                          }>
                          View
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      </div>
      {(!fetchingCustomers && customers?.data?.data?.length < 1) ||
      !customers?.data?.data ? (
        <EmptyState />
      ) : null}
      {/* {customers?.data?.data && customers?.data?.data?.length > 0 && (
        <div className="px-3">
          <TablePagination
            data={customers?.data?.data}
            totalCount={1}
            currentPage={1}
            pageSize={1}
            onPageChange={(page) =>
              setPage((prev) => ({ ...prev, page_number: page }))
            }
            totalPages={1}
          />
        </div>
      )} */}
    </div>
  );
};

export default CustomerEnrollment;
