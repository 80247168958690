import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import "./alertModal.scss";

const AlertModal = ({ show, toggleModal, onCancel, data }) => {
  const isSuccess = () => {
    if (typeof data?.data?.requestSuccessful === "boolean")
      return data?.data?.requestSuccessful;
    if (typeof data?.data?.hasError === "boolean") return !data?.data?.hasError;
    return undefined;
  };

  console.log("after onboarding", data);

  return (
    <Modal
      isOpen={!!show}
      toggle={toggleModal}
      centered
      size="lg"
      className="alert__modal"
    >
      <ModalHeader
        toggle={toggleModal}
        className="font-weight-bold title"
      ></ModalHeader>
      <ModalBody className="modal__body d-flex align-items-center justify-content-between gap-5 flex-column">
        <div className="d-flex align-items-center justify-content-center">
          <div
            className={`${
              !isSuccess() ? "error" : "success"
            }__icon d-flex align-items-center justify-content-center`}
          >
            <i
              className={`ri-${!isSuccess() ? "close" : "check"}-line ri-3x`}
            ></i>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div
            className={`font-weight-bold f-11 text-${
              isSuccess() ? "success" : "danger"
            }`}
          >
            {isSuccess()
              ? `Your onboarding ${
                  data?.data?.responseData?.providerName
                    ? "on" + data?.data?.responseData?.providerName
                    : ""
                } has been initiated.`
              : data?.data?.responseMessage ||
                data?.data?.Message ||
                "Something went wrong while trying to onboard merchant"}
          </div>
          <div className="msg-wrapper">
            <i
              className={`ri-${
                isSuccess()
                  ? "check-line text-success"
                  : "close-line text-danger"
              }`}
            ></i>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-100 d-flex align-items-center justify-content-center gap-2">
          <Button
            label="Close"
            background="blue"
            size="md"
            fullWidth
            variant="primary"
            onClick={() => onCancel()}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AlertModal;
