import { ViewBusinessMemberInfo } from "helpers/urls";
import PersonCard from "pages/CustomerEnrollment/CustomerInfo/PersonCard/PersonCard";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import "./people.scss";

const People = () => {
  const history = useHistory();
  const param = useParams();

  return (
    <div className="row px-4">
      <div className="col-md-4 col-lg-3">
        <PersonCard
          firstName="Rakia"
          email="rekiamann@mail.com"
          designation="CEO"
          onClick={() => history.push(ViewBusinessMemberInfo(param.id, 344))}
        />
      </div>
      <div className="col-md-4 col-lg-3">
        <PersonCard
          firstName="Calvin"
          email="calvinadam@mail.com"
          designation="Engineer"
          onClick={() => history.push(ViewBusinessMemberInfo(param.id, 345))}
        />
      </div>
    </div>
  );
};

export default People;
