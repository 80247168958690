import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import "./filterDropdown.scss";

const FilterDropdown = ({
  items,
  onItemClick,
  onToggle,
  children,
  activeItem,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      toggle={() => {
        setIsOpen(!isOpen);
        onToggle && onToggle({ isOpen });
      }}
      isOpen={isOpen}
    >
      <DropdownToggle className="sc-toggle-btn justify-content-center">
        <i className="ri-filter-3-line"></i>&nbsp;{" "}
        <span>{activeItem?.label || activeItem || "Filter"}</span> &nbsp;
        <i className={`ri-arrow-drop-${isOpen ? "up" : "down"}-line`}></i>
      </DropdownToggle>
      {children && (
        <DropdownMenu container="body" end>
          {children}
        </DropdownMenu>
      )}
      {items && !children && (
        <DropdownMenu className="mt-1" container="body" end>
          {items.map((item, idx) => (
            <DropdownItem
              key={idx}
              onClick={() => onItemClick && onItemClick(item)}
            >
              {item?.label || item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

export default FilterDropdown;
