import axios from "axios";
import { BehaviorSubject } from "rxjs";

import { baseUrl, config } from "./config.service";
import { signout } from "./user.service";

export const subscriber = new BehaviorSubject(0);

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error?.response?.status === 401) {
      signout();
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      signout();
    }
    return Promise.reject(error);
  }
);

export const Get = async (url, newUrl) => {
  try {
    return await axios.get(`${newUrl ? newUrl : baseUrl}/${url}`, config());
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const GetFile = async (url, newUrl) => {
  try {
    return await axios.get(`${newUrl ? newUrl : baseUrl}/${url}`, {
      ...config(),
      responseType: "arraybuffer",
    });
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Post = async (url, data, newUrl) => {
  try {
    return await axios.post(
      `${newUrl ? newUrl : baseUrl}/${url}`,
      data,
      config()
    );
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Patch = async (url, data) => {
  try {
    return await axios.patch(`${baseUrl}/${url}`, data, config());
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Put = async (url, data, newUrl) => {
  try {
    return await axios.put(
      `${newUrl ? newUrl : baseUrl}/${url}`,
      data,
      config()
    );
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export const Delete = async (url, data) => {
  try {
    return await axios.delete(`${baseUrl}/${url}`, config());
  } catch (error) {
    subscriber.next(error.response);
    throw error.response;
  }
};
