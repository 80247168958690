import React from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import "./changePlanModal.scss";

const ChangePlanModal = ({ show, toggleModal }) => {
  return (
    <Modal
      centered={true}
      fade={false}
      toggle={toggleModal}
      isOpen={show}
      backdrop="static"
      className="change-plan-modal-wrapper"
    >
      <ModalHeader className="border-bottom-0" toggle={toggleModal}>
        Change Plan
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column gap-5">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex align-items-center justify-content-center m-auto wrap-icon mb-3">
              <i className="ri-error-warning-line ri-3x text-primary"></i>
            </div>
            <h2 className="sc-change-title text-center">Moving to Premium</h2>
            <p className="sc-change-description text-center my-0 py-0">
              You’re about to change your plan. Are you sure about this
              decision?{" "}
            </p>
          </div>

          <div className="d-flex flex-column align-items-center gap-2">
            <Button
              label="Change"
              background="blue"
              className="w-100"
              onClick={() => {}}
            />
            <Button
              label="Cancel"
              className="w-100 cancel-btn"
              onClick={() => toggleModal()}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChangePlanModal;
