import React, { useCallback, useMemo, useState } from "react";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { NumericFormat } from "react-number-format";
import CurrencySelect from "components/CurrencySelect/CurrencySelect";

const defaultValues = {
  wallet: "",
  debit_amount: "",
  credit_amount: "",
};

const TransferForm = ({
  submitForm,
  sending,
  walletOptions,
  loadingWallets,
}) => {
  const [formValue, setFormValue] = useState(defaultValues);
  const [errorValues, setErrorValues] = useState(defaultValues);

  const values = useMemo(() => {
    return formValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue, setFormValue]);

  const onChange = useCallback(
    (e) => {
      setFormValue({ ...values, [e.target.name]: e.target.value });
    },
    [values]
  );

  const validateForm = () => {
    let validationErrors = {};
    if (!values.wallet) {
      validationErrors.wallet = "Wallet is required";
    }
    if (!values.credit_amount) {
      validationErrors.credit_amount = "Please enter an amount!";
    }
    if (!values.debit_amount) {
      validationErrors.debit_amount = "Please enter the amount you have!";
    }
    setErrorValues(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) return submitForm(values);
  };

  return (
    <form className="fade_in" onSubmit={onSubmit}>
      <div className="row">
        <div className="col-12 mb-3">
          <CurrencySelect
            data={walletOptions}
            label="Wallet"
            placeholder=""
            isLoading={loadingWallets}
            onSelect={(e) => {
              onChange({ target: { name: "wallet", value: e.value } || "" });
            }}
            onFocus={(e) => setErrorValues({ ...errorValues, wallet: "" })}
            onBlur={(e) => {
              if (values.wallet) {
                return setErrorValues({ ...errorValues, wallet: "" });
              }
              return setErrorValues({
                ...errorValues,
                wallet: "Wallet field is required!",
              });
            }}
            controlClassName={`form-control ${
              errorValues?.wallet ? "is-invalid" : ""
            } ps-0 pt-0 pb-0`}
            controlErrorMsg={errorValues?.wallet}
          />
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="debit_amount" className="form-label">
            Amount you have
          </label>
          <NumericFormat
            allowNegative={false}
            onValueChange={({ value }) => {
              onChange({
                target: {
                  name: "debit_amount",
                  value,
                },
              });
            }}
            onFocus={(e) =>
              setErrorValues({ ...errorValues, debit_amount: "" })
            }
            onBlur={() => {
              if (values.debit_amount) {
                return setErrorValues({ ...errorValues, debit_amount: "" });
              }
              return setErrorValues({
                ...errorValues,
                debit_amount: "Please enter the amount you have!",
              });
            }}
            thousandSeparator=","
            valueIsNumericString
            className={`form-control ${
              errorValues?.debit_amount ? "is-invalid" : ""
            } pt-0 pb-0`}
          />
          {errorValues?.debit_amount && (
            <small class="text-danger">{errorValues?.debit_amount}</small>
          )}
        </div>

        <div className="col-12 mb-2">
          <label htmlFor="credit_amount" className="form-label">
            Amount you're adding to this wallet{" "}
          </label>
          <NumericFormat
            allowNegative={false}
            onFocus={(e) =>
              setErrorValues({ ...errorValues, credit_amount: "" })
            }
            onValueChange={({ value }) => {
              onChange({
                target: {
                  name: "credit_amount",
                  value,
                },
              });
            }}
            onBlur={() => {
              if (values.credit_amount) {
                return setErrorValues({ ...errorValues, credit_amount: "" });
              }
              return setErrorValues({
                ...errorValues,
                credit_amount: "Please enter an amount!",
              });
            }}
            thousandSeparator=","
            valueIsNumericString
            className={`form-control ${
              errorValues?.credit_amount ? "is-invalid" : ""
            } pt-0 pb-0`}
          />
          {errorValues?.credit_amount && (
            <small class="text-danger">{errorValues?.credit_amount}</small>
          )}
        </div>
      </div>
      <div className="row fade_in pt-2">
        <div className="col-12">
          <Button
            type="submit"
            className="w-100"
            label={sending ? "Adding..." : "Add"}
            background="blue"
            size="md"
            style={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
            }}
            disabled={sending}
          />
        </div>
      </div>
    </form>
  );
};

export default TransferForm;
