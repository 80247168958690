import React from "react";
import document from "assets/images/doc.png";
import "./documentItem.scss";

const DocumentItem = ({onClick}) => {
  return (
    <div className="card sc-doc-item" onClick={() => onClick('data goes here')}>
      <img className="card-img-top" src={document} alt="Bologna" height={126} />
      <div className="card-body">
        <div className="d-flex align-items-end justify-content-between">
          <div className="d-flex flex-column gap-2">
            <h3 className="doc-title mb-0">Int. Passport</h3>
            <p className="doc-desc m-0">documentfile.pdf</p>
          </div>
          <div>
            <p className="doc-number m-0">94202321</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentItem;
