import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Popover, PopoverBody, Spinner } from "reactstrap";
import "./components.scss";

const DetailItem = ({ label, value, loading, useCopy }) => {
  const [clipboard, setClipboard] = useState({
    value: "",
    copied: false,
  });

  const handleCopy = (value) => {
    setClipboard({ value, copied: true });

    setTimeout(() => {
      setClipboard((prev) => ({ ...prev, copied: false }));
    }, 1000);
  };

  return (
    <div className="bank-detail d-flex align-items-center justify-content-between mb-5">
      <div className="ml-2 detail-label">{label}</div>
      <div className="detail-value">
        {loading ? (
          <Spinner color="primary" size="sm" />
        ) : (
          <div className="d-flex align-items-center">
            {value}

            {useCopy && (
              <>
                <CopyToClipboard
                  id="copytoclipboard"
                  text={clipboard.value}
                  onCopy={() => handleCopy(value)}
                >
                  <i
                    className="ri-file-copy-line ml-2 text-primary"
                    title="Copy"
                  ></i>
                </CopyToClipboard>
                <Popover
                  placement="top"
                  isOpen={clipboard.copied}
                  target="copytoclipboard"
                  toggle={clipboard.copied}
                >
                  <PopoverBody>Copied!</PopoverBody>
                </Popover>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailItem;
