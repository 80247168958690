import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import EmptyState from "components/emptyState/EmptyState";
import { ContentLoader } from "components/loader/Loader";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import DateRangePicker from "react-daterange-picker";
import { Card } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Typography from "vgg-ui-react/dist/components/Core/Typography";

export const chartOptions = {
  responsive: true,
  scales: {
    yAxes: {
      ticks: {
        callback: function (value, index, values) {
          // console.log(value, "y axis");
          let formatter = Intl.NumberFormat("en", {
            notation: "compact",
            compactDisplay: "short",
          });
          return formatter.format(value);
        },
      },
    },
    xAxes: {
      ticks: {},
    },
  },
  plugins: {
    legend: null,
    title: null,
    tooltip: {
      x: 300,
      y: 400,
      width: 300,
      height: 400,
      xAlign: "center",
      yAlign: "bottom",
      displayColors: false,
      backgroundColor: "#0A2540",
      bodyColor: "#fff",
      cornerRadius: 0,
      titleFont: { weight: 400, size: 12 },
      bodyFont: { weight: 600, size: 16 },
      footerFont: { weight: 400, size: 12 },
      footerSpacing: 10,
      padding: { top: 12, left: 48, right: 48, bottom: 12 },
      titleAlign: "center",
      bodyAlign: "center",
      footerAlign: "center",
      callbacks: {
        title: function (value) {
          const label = value[0]?.dataset?.label;
          return "Enrollment";
          // return `${label ? label : ""} Balance`.trim();
        },
        label: function (context) {
          let label = "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += `${context.parsed.y}`;
          }
          return label;
        },
        footer: function (context) {
          return context[0].label;
        },
      },
    },
  },
};

const EnrollmentChart = ({
  loading,
  dataSource,
  remount,
  dateRange,
  renderContent,
  onSelect,
  onClear,
}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (dataSource && dataSource.length) {
      const d = dataSource?.map((v) => v?.count);
      setData({
        labels: dataSource?.map(
          (v) => v?.date && format(new Date(v?.date), "dd MMM")
        ),
        datasets: [
          {
            label: "Deposit",
            data: d,
            borderColor: "#175FFF",
            borderWidth: 2,
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            tension: 0.3,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  return (
    <Card>
      <div className="sp-p--24 sp-mb--16 w-100 d-flex align-items-center justify-content-between bb-100">
        <Typography typographyVariant="section-title" text="Enrollment Rate" />
        <div className="d-flex align-items-center justify-content-end">
          <FilterDropdown activeItem={renderContent(dateRange)}>
            {!remount && (
              <DateRangePicker
                numberOfCalendars={2}
                value={dateRange}
                onSelect={onSelect}
                className="sc-reports"
              />
            )}
            <div className="py-2 px-3">
              {dateRange && (
                <Button
                  label="Clear Selection"
                  background="blue"
                  className="w-100"
                  onClick={() => onClear && onClear()}
                />
              )}
            </div>
          </FilterDropdown>
        </div>
      </div>
      {loading ? (
        <ContentLoader height="36vh" />
      ) : (
        <div className="ps-4  pt-3 pb-5">
          {data ? (
            <Line options={chartOptions} data={data} />
          ) : (
            <div className="d-flex align-items-center justify-content-center w-100">
              <EmptyState />
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default EnrollmentChart;
