import { enums } from "helpers";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Popover, PopoverBody, Spinner } from "reactstrap";
import "./components.scss";
import DetailItem from "./DetailItem";

const AccountDetails = ({ loading, data, selectedWallet }) => {
  const [clipboard, setClipboard] = useState({
    value: "",
    copied: false,
  });

  const handleCopy = (value) => {
    setClipboard({ value, copied: true });

    setTimeout(() => {
      setClipboard((prev) => ({ ...prev, copied: false }));
    }, 1000);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <DetailItem label="Beneficiary Bank Swift Code" value="ZEIBNGLA" />
          <DetailItem label="Beneficiary Bank" value="Zenith Bank Plc." />
          <DetailItem
            label="Beneficiary Bank Account No"
            value="5072326784"
            useCopy
          />
          <DetailItem
            label="Intermediary Bank Account No"
            value="021000089"
            useCopy
          />
          <DetailItem
            label="Intermediary Bank Swift Address"
            value="CITIUS33"
          />
          <DetailItem label="Intermediary Bank ABA No" value="36204566(USD)" />
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
