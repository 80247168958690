import { businesses, customers, transactions } from "helpers/data";
import { instanceActionTypes } from "../actions/smartCheckInstance";
import { FAILURE, REQUEST, SUCCESS } from "./action-type.util";

const INITIAL_STATE = {
  checkingMerchantStatus: false,
  merchantStatus: null,
  merchantStatusError: null,

  transactions: {
    data: transactions,
    pages: 1,
    count: 12,
    page_number: 1,
    page_size: 12,
  },
  fetchingTransactions: false,
  transactionsError: null,

  customers: {
    data: customers,
    pages: 1,
    count: 12,
    page_number: 1,
    page_size: 12,
  },
  customersError: null,
  fetchingCustomers: false,

  businesses: {
    data: businesses,
    pages: 1,
    count: 12,
    page_number: 1,
    page_size: 12,
  },
  businessesError: null,
  fetchingBusinesses: false,

  fetchingLinks: false,
  linksData: null,
  linksDataError: null,

  fetchingPlans: false,
  plansData: null,
  plansError: null,
};

const smartCheckReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(instanceActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        checkingMerchantStatus: true,
        merchantStatus: null,
        merchantStatusError: null,
      };
    case SUCCESS(instanceActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        checkingMerchantStatus: false,
        merchantStatus: actions.payload,
        merchantStatusError: null,
      };
    case FAILURE(instanceActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        merchantStatus: null,
        checkingMerchantStatus: false,
        merchantStatusError: actions.payload,
      };

    case REQUEST(instanceActionTypes.FETCH_TRANSACTIONS):
      return {
        ...state,
        transactions: null,
        transactionsError: null,
        fetchingTransactions: true,
      };
    case SUCCESS(instanceActionTypes.FETCH_TRANSACTIONS):
      return {
        ...state,
        fetchingTransactions: false,
        transactions: actions?.payload?.data,
        transactionsError: null,
      };
    case FAILURE(instanceActionTypes.FETCH_TRANSACTIONS):
      return {
        ...state,
        transactions: null,
        transactionsError: actions?.payload,
        fetchingTransactions: false,
      };
    case REQUEST(instanceActionTypes.FETCH_TRANSACTIONS_DETAILS):
      return {
        ...state,
        transactionsDetails: null,
        transactionsDetailsError: null,
        fetchingTransactionsDetails: true,
      };
    case SUCCESS(instanceActionTypes.FETCH_TRANSACTIONS_DETAILS):
      return {
        ...state,
        fetchingTransactionsDetails: false,
        transactionsDetails: actions?.payload?.data,
        transactionsDetailsError: null,
      };
    case FAILURE(instanceActionTypes.FETCH_TRANSACTIONS_DETAILS):
      return {
        ...state,
        transactionsDetails: null,
        transactionsDetailsError: actions?.payload,
        fetchingTransactionsDetails: false,
      };

    case REQUEST(instanceActionTypes.FETCH_CUSTOMER_ENROLLMENTS):
      return {
        ...state,
        customers: null,
        customersError: null,
        fetchingCustomers: true,
      };
    case SUCCESS(instanceActionTypes.FETCH_CUSTOMER_ENROLLMENTS):
      return {
        ...state,
        customers: actions?.payload,
        customersError: null,
        fetchingCustomers: false,
      };
    case FAILURE(instanceActionTypes.FETCH_CUSTOMER_ENROLLMENTS):
      return {
        ...state,
        customers: null,
        customersError: actions?.payload,
        fetchingCustomers: false,
      };
    case REQUEST(instanceActionTypes.FETCH_CUSTOMER_ENROLMENT_DETAILS):
      return {
        ...state,
        customersDetails: null,
        customersDetailsError: null,
        fetchingCustomersDetails: true,
      };
    case SUCCESS(instanceActionTypes.FETCH_CUSTOMER_ENROLMENT_DETAILS):
      return {
        ...state,
        fetchingCustomersDetails: false,
        customersDetails: actions?.payload?.data,
        customersDetailsError: null,
      };
    case FAILURE(instanceActionTypes.FETCH_CUSTOMER_ENROLMENT_DETAILS):
      return {
        ...state,
        customersDetails: null,
        customersDetailsError: actions?.payload,
        fetchingCustomersDetails: false,
      };

    case REQUEST(instanceActionTypes.FETCH_BUSINESS_ENROLLMENTS):
      return {
        ...state,
        businesses: null,
        businessesError: null,
        fetchingBusinesses: true,
      };
    case SUCCESS(instanceActionTypes.FETCH_BUSINESS_ENROLLMENTS):
      return {
        ...state,
        fetchingBusinesses: false,
        businesses: actions?.payload?.data,
        businessesError: null,
      };
    case FAILURE(instanceActionTypes.FETCH_BUSINESS_ENROLLMENTS):
      return {
        ...state,
        businesses: null,
        businessesError: actions?.payload,
        fetchingBusinesses: false,
      };

    case REQUEST(instanceActionTypes.FETCH_LINKS):
      return {
        ...state,
        fetchingLinks: true,
        linksData: null,
        linksDataError: null,
      };
    case SUCCESS(instanceActionTypes.FETCH_LINKS):
      return {
        ...state,
        fetchingLinks: false,
        linksData: actions?.payload?.data,
        linksDataError: null,
      };
    case FAILURE(instanceActionTypes.FETCH_LINKS):
      return {
        ...state,
        fetchingLinks: false,
        linksData: null,
        linksDataError: actions?.payload,
      };

    case REQUEST(instanceActionTypes.FETCH_PLANS):
      return {
        ...state,
        fetchingPlans: true,
        plansData: null,
        plansError: null,
      };
    case SUCCESS(instanceActionTypes.FETCH_PLANS):
      return {
        ...state,
        fetchingPlans: false,
        plansData: actions?.payload?.data,
        plansError: null,
      };
    case FAILURE(instanceActionTypes.FETCH_PLANS):
      return {
        ...state,
        plansError: actions?.payload,
        plansData: null,
        fetchingPlans: false,
      };

    case REQUEST(instanceActionTypes.GET_COUNTRIES):
      return {
        ...state,
        countries: null,
        countriesError: null,
        loadingCountries: true,
      };
    case SUCCESS(instanceActionTypes.GET_COUNTRIES):
      return {
        ...state,
        loadingCountries: false,
        countries: actions?.payload?.data?.responseData,
      };
    case FAILURE(instanceActionTypes.GET_COUNTRIES):
      return {
        ...state,
        countriesError: actions?.payload,
        loadingCountries: false,
      };

    case REQUEST(instanceActionTypes.GET_CURRENCIES):
      return {
        ...state,
        currencies: [],
        currenciesError: null,
        loadingCurrencies: true,
      };
    case SUCCESS(instanceActionTypes.GET_CURRENCIES):
      return {
        ...state,
        loadingCurrencies: false,
        currencies: actions?.payload?.data?.responseData,
      };

    case FAILURE(instanceActionTypes.GET_CURRENCIES):
      return {
        ...state,
        currenciesError: actions?.payload,
        loadingCurrencies: false,
      };

    case REQUEST(instanceActionTypes.CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: true,
        createInstanceSuccess: false,
        createInstanceError: null,
      };
    case SUCCESS(instanceActionTypes.CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: false,
        createInstanceSuccess: true,
        createInstanceError: null,
      };
    case FAILURE(instanceActionTypes.CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: false,
        createInstanceSuccess: false,
        createInstanceError: actions?.payload?.data,
      };

    case REQUEST(instanceActionTypes.RESET_CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: false,
        createInstanceSuccess: false,
        createInstanceError: null,
      };

    case REQUEST(instanceActionTypes.GET_KYC_SUMMARY):
      return {
        ...state,
        loadingKycSummary: true,
        kycSummaryError: null,
      };
    case SUCCESS(instanceActionTypes.GET_KYC_SUMMARY):
      return {
        ...state,
        loadingKycSummary: false,
        kycSummary: actions?.payload?.data,
        kycSummaryError: null,
      };

    case FAILURE(instanceActionTypes.GET_KYC_SUMMARY):
      return {
        ...state,
        loadingKycSummary: false,
        kycSummaryError: actions?.payload?.data,
      };

    case REQUEST(instanceActionTypes.GET_KYC_HISTORY):
      return {
        ...state,
        loadingKycHistory: true,
        kycHistory: [],
        kycHistoryError: null,
      };
    case SUCCESS(instanceActionTypes.GET_KYC_HISTORY):
      return {
        ...state,
        loadingKycHistory: false,
        kycHistory: actions?.payload?.data,
        kycHistoryError: null,
      };

    case FAILURE(instanceActionTypes.GET_KYC_HISTORY):
      return {
        ...state,
        kycHistory: { items: [] },
        kycHistoryError: actions?.payload,
        loadingKycHistory: false,
      };

    case REQUEST(instanceActionTypes.GET_ACCOUNTS):
      return {
        ...state,
        loadingAccounts: true,
        accounts: [],
        accountsError: null,
      };
    case SUCCESS(instanceActionTypes.GET_ACCOUNTS):
      return {
        ...state,
        loadingAccounts: false,
        accounts: actions?.payload?.data,
        accountsError: null,
      };

    case FAILURE(instanceActionTypes.GET_ACCOUNTS):
      return {
        ...state,
        accountsError: actions?.payload,
        loadingAccounts: false,
      };

    case REQUEST(instanceActionTypes.GET_CHART_DATA):
      return {
        ...state,
        loadingWalletChart: true,
        walletChart: [],
        walletChartError: null,
      };
    case SUCCESS(instanceActionTypes.GET_CHART_DATA):
      return {
        ...state,
        loadingWalletChart: false,
        walletChart: converChart(actions?.payload?.data),
        walletChartError: null,
      };

    case FAILURE(instanceActionTypes.GET_CHART_DATA):
      return {
        ...state,
        walletChartError: actions?.payload,
        loadingWalletChart: false,
      };

    case REQUEST(instanceActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchingStatus: true,
        switchStatusSuccess: null,
        switchStatusError: null,
      };
    case SUCCESS(instanceActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchingStatus: false,
        switchStatusSuccess: actions?.payload,
        switchStatusError: null,
      };

    case FAILURE(instanceActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchStatusSuccess: null,
        switchStatusError: actions?.payload,
        switchingStatus: false,
      };
    case REQUEST(instanceActionTypes.GET_VALIDATE_IDENTITY_TYPES):
      return {
        ...state,
        loadingValidateIndentityTypes: true,
        validateIdentityTypesSuccess: null,
        validateIdentityTypesError: null,
      };
    case SUCCESS(instanceActionTypes.GET_VALIDATE_IDENTITY_TYPES):
      return {
        ...state,
        loadingValidateIndentityTypes: false,
        validateIdentityTypesSuccess: actions.payload,
        validateIdentityTypesError: null,
      };
    case FAILURE(instanceActionTypes.GET_VALIDATE_IDENTITY_TYPES):
      return {
        ...state,
        loadingValidateIndentityTypes: false,
        validateIdentityTypesSuccess: null,
        validateIdentityTypesError: actions.payload,
      };
    case REQUEST(instanceActionTypes.VALIDATE_IDENTITY):
      return {
        ...state,
        validatingIdentity: true,
        validateIdentitySuccess: null,
        validateIdentityError: null,
      };
    case SUCCESS(instanceActionTypes.VALIDATE_IDENTITY):
      return {
        ...state,
        validatingIdentity: false,
        validateIdentitySuccess: actions.payload,
        validateIdentityError: null,
      };
    case FAILURE(instanceActionTypes.VALIDATE_IDENTITY):
      return {
        ...state,
        validatingIdentity: false,
        validateIdentitySuccess: null,
        validateIdentityError: actions.payload,
      };
    case REQUEST(instanceActionTypes.VALIDATE_IDENTITY_DATA):
      return {
        ...state,
        validatingIdentityData: true,
        validateIdentityDataSuccess: null,
        validateIdentityDataError: null,
      };
    case SUCCESS(instanceActionTypes.VALIDATE_IDENTITY_DATA):
      return {
        ...state,
        validatingIdentityData: false,
        validateIdentityDataSuccess: actions.payload,
        validateIdentityDataError: null,
      };
    case FAILURE(instanceActionTypes.VALIDATE_IDENTITY_DATA):
      return {
        ...state,
        validatingIdentityData: false,
        validateIdentityDataSuccess: null,
        validateIdentityDataError: actions.payload,
      };
    case REQUEST(instanceActionTypes.DOWNLOAD_REPORT):
      return {
        ...state,
        identityDataLoading: true,
        identityDataSuccess: null,
        identityDataError: null,
      };
    case SUCCESS(instanceActionTypes.DOWNLOAD_REPORT):
      return {
        ...state,
        identityDataLoading: false,
        identityDataSuccess: actions.payload,
        identityDataError: null,
      };
    case FAILURE(instanceActionTypes.DOWNLOAD_REPORT):
      return {
        ...state,
        identityDataLoading: false,
        identityDataSuccess: null,
        identityDataError: actions.payload,
      };

    case REQUEST(instanceActionTypes.API_LOGS):
      return {
        ...state,
        apiLogsLoading: true,
        apiLogsPayload: null,
        apiLogsError: null,
      };
    case SUCCESS(instanceActionTypes.API_LOGS):
      return {
        ...state,
        apiLogsLoading: false,
        apiLogsPayload: actions?.payload,
        apiLogsError: null,
      };

    case FAILURE(instanceActionTypes.API_LOGS):
      return {
        ...state,
        apiLogsPayload: null,
        apiLogsError: actions?.payload,
        apiLogsLoading: false,
      };

    case REQUEST(instanceActionTypes.COSTS):
      return {
        ...state,
        costsLoading: true,
        costsPayload: null,
        costsError: null,
      };
    case SUCCESS(instanceActionTypes.COSTS):
      return {
        ...state,
        costsLoading: false,
        costsPayload: actions?.payload,
        costsError: null,
      };

    case FAILURE(instanceActionTypes.COSTS):
      return {
        ...state,
        costsPayload: null,
        costsError: actions?.payload,
        costsLoading: false,
      };

    case REQUEST(instanceActionTypes.REQUEST_ACCESS):
      return {
        ...state,
        requestingAccess: true,
        requestAccessPayload: null,
        requestAccessError: null,
      };
    case SUCCESS(instanceActionTypes.REQUEST_ACCESS):
      return {
        ...state,
        requestingAccess: false,
        requestAccessPayload: actions?.payload,
        requestAccessError: null,
      };

    case FAILURE(instanceActionTypes.REQUEST_ACCESS):
      return {
        ...state,
        requestAccessPayload: null,
        requestAccessError: actions?.payload,
        requestingAccess: false,
      };
    case REQUEST(instanceActionTypes.FETCH_SUPPORTED_CURRENCIES):
      return {
        ...state,
        fetchingCurrencies: true,
        currenciesPayload: null,
        currenciesError: null,
      };
    case SUCCESS(instanceActionTypes.FETCH_SUPPORTED_CURRENCIES):
      return {
        ...state,
        fetchingCurrencies: false,
        currenciesPayload: actions?.payload,
        currenciesError: null,
      };

    case FAILURE(instanceActionTypes.FETCH_SUPPORTED_CURRENCIES):
      return {
        ...state,
        currenciesPayload: null,
        currenciesError: actions?.payload,
        fetchingCurrencies: false,
      };
    case REQUEST(instanceActionTypes.FETCH_SETTINGS_DATA):
      return {
        ...state,
        fetchingSettingsData: true,
        settingsDataPayload: null,
        settingsDataError: null,
      };
    case SUCCESS(instanceActionTypes.FETCH_SETTINGS_DATA):
      return {
        ...state,
        fetchingSettingsData: false,
        settingsDataPayload: actions?.payload,
        settingsDataError: null,
      };
    case FAILURE(instanceActionTypes.FETCH_SETTINGS_DATA):
      return {
        ...state,
        settingsDataPayload: null,
        settingsDataError: actions?.payload,
        fetchingSettingsData: false,
      };
    case REQUEST(instanceActionTypes.GET_ANALYTICS_COUNT):
      return {
        ...state,
        analyticsCountLoading: true,
        analyticsCountPayload: null,
        analyticsCountError: null,
      };
    case SUCCESS(instanceActionTypes.GET_ANALYTICS_COUNT):
      return {
        ...state,
        analyticsCountLoading: false,
        analyticsCountPayload: actions?.payload,
        analyticsCountError: null,
      };
    case FAILURE(instanceActionTypes.GET_ANALYTICS_COUNT):
      return {
        ...state,
        analyticsCountPayload: null,
        analyticsCountError: actions?.payload,
        analyticsCountLoading: false,
      };
    case REQUEST(instanceActionTypes.GET_ENROLMENT_RATE):
      return {
        ...state,
        enrollmentsRateLoading: true,
        enrollmentsRatePayload: null,
        enrollmentsRateError: null,
      };
    case SUCCESS(instanceActionTypes.GET_ENROLMENT_RATE):
      return {
        ...state,
        enrollmentsRateLoading: false,
        enrollmentsRatePayload: actions?.payload,
        enrollmentsRateError: null,
      };
    case FAILURE(instanceActionTypes.GET_ENROLMENT_RATE):
      return {
        ...state,
        enrollmentsRatePayload: null,
        enrollmentsRateError: actions?.payload,
        enrollmentsRateLoading: false,
      };
    case REQUEST(instanceActionTypes.GET_FUND_BALANCE):
      return {
        ...state,
        fundBalanceLoading: true,
        fundBalancePayload: null,
        fundBalanceError: null,
      };
    case SUCCESS(instanceActionTypes.GET_FUND_BALANCE):
      return {
        ...state,
        fundBalanceLoading: false,
        fundBalancePayload: actions?.payload,
        fundBalanceError: null,
      };
    case FAILURE(instanceActionTypes.GET_FUND_BALANCE):
      return {
        ...state,
        fundBalancePayload: null,
        fundBalanceError: actions?.payload,
        fundBalanceLoading: false,
      };
    default:
      return { ...state };
  }
};

export default smartCheckReducer;

function converChart(data) {
  const xLabel = [];
  const credited = [];
  const debited = [];

  for (let item of data) {
    xLabel.push(item.transactionDate);
    credited.push(item.credited);
    debited.push(item.debited);
  }
  return {
    labels: xLabel,
    datasets: [
      {
        label: "Credited",
        data: credited,
        fill: true,
        backgroundColor: "rgba(23, 95, 255, 0.1)",
        borderColor: "#175FFF",
      },
      {
        label: "Debited",
        data: debited,
        fill: true,
        backgroundColor: "rgba(255, 105, 105, 0.05)",
        borderColor: "#FF6969",
      },
    ],
  };
}
