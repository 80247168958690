import BackButton from "components/BackButton/BackButton";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import CustomTab from "components/CustomTab/CustomTab";
import URLS, {
  ViewBusinessDocument,
  ViewBusinessInfo,
  ViewPeople,
} from "helpers/urls";
import React, { useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import BusinessDocument from "./BusinessDocument/BusinessDocument";
import People from "./People/People";

import "./businessInfo.scss";

const breadcrumbItems = (name) => [
  {
    id: 1,
    name: "Business Enrollment",
    path: URLS.BusinessEnrollment,
  },
  {
    id: 2,
    name,
    path: "",
  },
];

const tabItems = (id) => [
  {
    id: 1,
    title: "Business Document",
    key: "business-document",
    routeLink: ViewBusinessDocument(id),
    component: BusinessDocument,
  },
  {
    id: 2,
    title: "People",
    key: "people",
    routeLink: ViewPeople(id),
    component: People,
  },
];

const BusinessInfo = () => {
  const history = useHistory();
  const param = useParams();
  const [activeTab, setActiveTab] = useState(tabItems(param.id)[0]?.key);

  const handlePathChange = (path) => {
    switch (path) {
      case ViewBusinessInfo(param.id):
        console.log("ana");
        history.push(ViewBusinessDocument(param.id));
        break;
      case ViewBusinessDocument(param.id):
        setActiveTab("business-document");
        break;
      case ViewPeople(param.id):
        setActiveTab("people");
        break;

      default:
        setActiveTab("business-document");
    }
  };

  return (
    <div className="service-details customer-info mb-5">
      <BackButton
        label="Go Back"
        onClick={() => {
          const url = history.location.pathname.includes("customer")
            ? URLS.CustomerEnrollment
            : URLS.BusinessEnrollment;
          history.push(url, { isBack: true });
        }}
      />

      <div className="my-3">
        <Breadcrumb items={breadcrumbItems("Shoprite Co.")} />
      </div>

      <div className="table-wrapper customer-info">
        <div className="py-3">
          <CustomTab
            listClassNames="px-3"
            data={tabItems(param.id)}
            onPathChange={handlePathChange}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            // defaultActiveTab={
            //   tabItems.find((v) => v.routeLink === history?.location?.pathname)
            //     ?.key
            // }
          >
            <Switch>
              {tabItems(param.id).map(
                ({ key, routeLink, component: Component }) => (
                  <Route
                    key={key}
                    exact
                    path={routeLink}
                    render={() => <Component />}
                  />
                )
              )}
            </Switch>
          </CustomTab>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;
