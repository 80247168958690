import React from "react";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { ReactComponent as InfoIcon } from "../../assets/images/infoLarge.svg";
import "./awaitingApprovalPage.scss";
import BackButton from "components/BackButton/BackButton";

const AwaitingApprovalPage = ({
  description = "Your onboarding request has been successfully submitted and feedback will be provided shortly.",
}) => {
  const onOnboardClick = () => {};

  return (
    <div className="awaiting-onboard-wrapper">
      <BackButton label="Go to Products" remixCode="close-line" />
      <div className="d-flex flex-column align-items-center justify-content-center content-wrapper">
        <div className="mb-3">
          <InfoIcon />
        </div>
        <div className="mb-3">
          <h3 className="success-title">Onboard Pending!</h3>
        </div>
        <p className="mb-3 success-content">{description}</p>
        {<p className="mb-3 success-content"></p>}

        {false && (
          <Button
            label="Onboard New Provider"
            background="blue"
            onClick={onOnboardClick}
          />
        )}
      </div>
    </div>
  );
};

export default AwaitingApprovalPage;
