export const transactions = [
  {
    id: 1,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
  {
    id: 12,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
  {
    id: 13,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
  {
    id: 14,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
  {
    id: 15,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
  {
    id: 16,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
  {
    id: 17,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
  {
    id: 18,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
  {
    id: 19,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
  {
    id: 110,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
  {
    id: 111,
    transaction_ref: "90240294203",
    total_amount: "$5,000",
    new_balance: "$10,000",
    account: "Ava. Balance",
    status: "Successful",
    created_at: "12/08/21",
  },
];

export const customers = [
  {
    id: 1,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 12,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 13,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 14,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 15,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 16,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 17,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 18,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 19,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 110,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 111,
    customer_identifier: "90240294203",
    email_address: "johnson@mail.com",
    full_name: "Adebayo Johnson",
    country: "Nigeria",
    created_at: "12/08/21",
  },
];

export const businesses = [
  {
    id: 1,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 12,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 13,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 14,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 15,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 16,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 17,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 18,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 19,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 110,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
  {
    id: 111,
    business_identifier: "90240294203",
    business_name: "Shoprite Corp.",
    status: "Enabled",
    country: "Nigeria",
    created_at: "12/08/21",
  },
];

export const DUMMY_SUBSCRIPTIONS = [
  {
    title: "Pay as you go",
    description_short: "Plan and Pricing Description",
    description_long: "Plan and Pricing Description",
    price: 0,
    current_plan: true,
    popular: false,
    billable_items: [
      {
        id: 1,
        name: "AML",
        price: 1,
      },
      {
        id: 2,
        name: "Basic Verification",
        price: 1,
      },
      {
        id: 3,
        name: "Selfie Check",
        price: 1,
      },
      {
        id: 4,
        name: "Liveliness Check",
        price: 1,
      },
    ],
  },
  {
    title: "Basic",
    description_short: "Plan and Pricing Description",
    description_long: "",
    price: 500,
    current_plan: false,
    popular: false,
    billable_items: [
      {
        id: 1,
        name: "AML",
        price: 1,
      },
      {
        id: 2,
        name: "Basic Verification",
        price: 1,
      },
      {
        id: 3,
        name: "Selfie Check",
        price: 1,
      },
      {
        id: 4,
        name: "Liveliness Check",
        price: 1,
      },
    ],
  },
  {
    title: "Premium",
    description_short: "Plan and Pricing Description",
    description_long: "Plan and Pricing Description",
    price: 1000,
    current_plan: false,
    popular: true,
    billable_items: [
      {
        id: 1,
        name: "AML",
        price: 1,
      },
      {
        id: 2,
        name: "Basic Verification",
        price: 1,
      },
      {
        id: 3,
        name: "Selfie Check",
        price: 1,
      },
      {
        id: 4,
        name: "Liveliness Check",
        price: 1,
      },
    ],
  },
  {
    title: "Enterprise",
    description_short: "Plan and Pricing Description",
    description_long: "Plan and Pricing Description",
    price: null,
    current_plan: false,
    popular: false,
    billable_items: [
      {
        id: 1,
        name: "AML",
        price: 1,
      },
      {
        id: 2,
        name: "Basic Verification",
        price: 1,
      },
      {
        id: 3,
        name: "Selfie Check",
        price: 1,
      },
      {
        id: 4,
        name: "Liveliness Check",
        price: 1,
      },
    ],
  },
];

export const DUMMY_TRANSACTIONS = {
  data: [
    {
      transactionDate: "2023-05-04T00:00:13.8740948",
      dayOfyear: 124,
      africaOneClientId: "3",
      currency: "NGN",
      accountNo: "000046068",
      availableBalance: 200000.0,
      accountBalance: 0.0,
      withrawals: 0.0,
      deposits: 0.0,
      id: "0a4a6508-b15f-464c-332e-08db477b8708",
      createdAt: "2023-05-04T00:00:13.8740929+00:00",
      updatedAt: null,
      isDeleted: false,
      deletedAt: null,
    },
    {
      transactionDate: "2023-05-05T00:00:16.4408468",
      dayOfyear: 125,
      africaOneClientId: "3",
      currency: "NGN",
      accountNo: "000046068",
      availableBalance: 150000.0,
      accountBalance: 0.0,
      withrawals: 0.0,
      deposits: 0.0,
      id: "1b33a472-2ca9-486f-3365-08db477b8708",
      createdAt: "2023-05-05T00:00:16.4408451+00:00",
      updatedAt: null,
      isDeleted: false,
      deletedAt: null,
    },
    {
      transactionDate: "2023-05-06T00:00:18.0857172",
      dayOfyear: 126,
      africaOneClientId: "3",
      currency: "NGN",
      accountNo: "000046068",
      availableBalance: 274000.0,
      accountBalance: 0.0,
      withrawals: 0.0,
      deposits: 0.0,
      id: "e8a0dd8a-a764-43a2-339c-08db477b8708",
      createdAt: "2023-05-06T00:00:18.0857153+00:00",
      updatedAt: null,
      isDeleted: false,
      deletedAt: null,
    },
    {
      transactionDate: "2023-05-07T00:00:20.6130257",
      dayOfyear: 127,
      africaOneClientId: "3",
      currency: "NGN",
      accountNo: "000046068",
      availableBalance: 85500.67,
      accountBalance: 0.0,
      withrawals: 0.0,
      deposits: 0.0,
      id: "9db32d56-b1df-4502-33d3-08db477b8708",
      createdAt: "2023-05-07T00:00:20.6130241+00:00",
      updatedAt: null,
      isDeleted: false,
      deletedAt: null,
    },
    {
      transactionDate: "2023-05-08T00:00:17.0864067",
      dayOfyear: 128,
      africaOneClientId: "3",
      currency: "NGN",
      accountNo: "000046068",
      availableBalance: 128250.52,
      accountBalance: 0.0,
      withrawals: 0.0,
      deposits: 0.0,
      id: "0cdc0a0e-e8c5-4ea7-340a-08db477b8708",
      createdAt: "2023-05-08T00:00:17.0864049+00:00",
      updatedAt: null,
      isDeleted: false,
      deletedAt: null,
    },
    {
      transactionDate: "2023-05-09T00:00:15.4999881",
      dayOfyear: 129,
      africaOneClientId: "3",
      currency: "NGN",
      accountNo: "000046068",
      availableBalance: 42360.0,
      accountBalance: 0.0,
      withrawals: 0.0,
      deposits: 0.0,
      id: "5b1536cc-9d4f-4e0e-3441-08db477b8708",
      createdAt: "2023-05-09T00:00:15.4999861+00:00",
      updatedAt: null,
      isDeleted: false,
      deletedAt: null,
    },
    {
      transactionDate: "2023-05-10T00:00:15.7507598",
      dayOfyear: 130,
      africaOneClientId: "3",
      currency: "NGN",
      accountNo: "000046068",
      availableBalance: 365000.0,
      accountBalance: 0.0,
      withrawals: 0.0,
      deposits: 0.0,
      id: "d5fdf0d8-73b9-456f-3478-08db477b8708",
      createdAt: "2023-05-10T00:00:15.750758+00:00",
      updatedAt: null,
      isDeleted: false,
      deletedAt: null,
    },
    {
      transactionDate: "2023-05-11T00:00:21.5928903",
      dayOfyear: 131,
      africaOneClientId: "3",
      currency: "NGN",
      accountNo: "000046068",
      availableBalance: 56770.57,
      accountBalance: 0.0,
      withrawals: 0.0,
      deposits: 0.0,
      id: "c1815c3d-bb3a-481d-34af-08db477b8708",
      createdAt: "2023-05-11T00:00:21.5928883+00:00",
      updatedAt: null,
      isDeleted: false,
      deletedAt: null,
    },
    {
      transactionDate: "2023-05-12T00:00:19.2041721",
      dayOfyear: 132,
      africaOneClientId: "3",
      currency: "NGN",
      accountNo: "000046068",
      availableBalance: 99988.0,
      accountBalance: 0.0,
      withrawals: 0.0,
      deposits: 0.0,
      id: "313b9075-f5ab-40f0-34e6-08db477b8708",
      createdAt: "2023-05-12T00:00:19.2041702+00:00",
      updatedAt: null,
      isDeleted: false,
      deletedAt: null,
    },
    {
      transactionDate: "2023-05-13T00:00:18.5312471",
      dayOfyear: 133,
      africaOneClientId: "3",
      currency: "NGN",
      accountNo: "000046068",
      availableBalance: 150000.0,
      accountBalance: 0.0,
      withrawals: 0.0,
      deposits: 0.0,
      id: "04738c0f-d5e9-4ddf-351d-08db477b8708",
      createdAt: "2023-05-13T00:00:18.5312452+00:00",
      updatedAt: null,
      isDeleted: false,
      deletedAt: null,
    },
  ],
  status: true,
  message: "Request process successfully",
  responseCode: "00",
};

export const enums = {
  colours: [],
  dropdownSizes: [],

  bankAccounts: {
    default: {
      accountNumber: "5072326784",
      accountName: "VENTURE GARDEN NIG LTD - AFRICA ONE",
      bankName: "Zenith Bank PLC",
    },

    // usd: {
    //   bankCode: "07105",
    //   accountNumber: "3739470045",
    //   accountName: "DukaPay Limited",
    //   bankName: "NCBA"
    // },

    kes: {
      bankName: "NCBA Bank Kenya",
      accountNumber: "3739470045",
      accountName: "Duka Pay Ltd",
      bankCode: "07106",
    },
  },
};
