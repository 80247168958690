import { ReactComponent as BalanceIcon } from "assets/images/balance.svg";
import EnrollmentChart from "components/EnrollmentChart/EnrollmentChart";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticsCount,
  getEnrollmentRate,
  getFundBalance,
} from "redux/actions/smartCheckInstance";

import swal from "@sweetalert/with-react";
import URLS from "helpers/urls";
import "react-daterange-picker/dist/css/react-calendar.css";
import { useHistory } from "react-router-dom";
import AddFunds from "./AddFunds/AddFunds";
import "./overview.scss";

const Overview = ({ currencies, fetchingCurrencies }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAddFund, setShowAddFund] = useState(false);
  const {
    fundBalanceLoading,
    fundBalancePayload,
    fundBalanceError,
    enrollmentsRateLoading,
    enrollmentsRatePayload,
    analyticsCountLoading,
    analyticsCountPayload,
  } = useSelector(({ smartCheck }) => smartCheck);
  const [dateRange, setDateRange] = useState(null);
  const [activeCurrency, setActiveCurrency] = useState("");
  const [remount, setRemount] = useState(false);
  const [balance, setBalance] = useState(0);
  const [analyticsCount, setAnalyticsCount] = useState(0);

  useEffect(() => {
    dispatch(getFundBalance());
    dispatch(getEnrollmentRate());
    dispatch(getAnalyticsCount());
  }, [dispatch]);

  useEffect(() => {
    if (
      fundBalancePayload?.data &&
      fundBalancePayload?.data.requestSuccessful
    ) {
      setBalance(fundBalancePayload?.data?.data?.primary_account_balance);
    }
  }, [fundBalancePayload]);

  useEffect(() => {
    if (
      analyticsCountPayload?.data &&
      analyticsCountPayload?.data.requestSuccessful
    ) {
      setAnalyticsCount(analyticsCountPayload?.data?.data);
    }
  }, [analyticsCountPayload]);

  useEffect(() => {
    if (!fetchingCurrencies && currencies) {
      setActiveCurrency(currencies[0]?.currency);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies, fetchingCurrencies]);

  // useEffect(() => {
  //   if (dateRange || activeCurrency) {
  //     dispatch(
  //       fetchCosts({
  //         currency: activeCurrency,
  //         startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
  //         endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
  //       })
  //     );
  //   }
  //   if (remount) {
  //     setRemount(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dateRange, activeCurrency]);

  const onSelect = (value) => {
    const start = value.start;
    const end = value.end;
    const dateCount = end.diff(start, "days") + 1;
    if (dateCount > 30) {
      swal({
        text: "Kindly select range to be at most 30 days.",
        icon: "info",
        button: "Okay",
      });
    } else {
      setDateRange(value);
    }
  };

  const onClearSelection = () => {
    setDateRange(null);
  };

  const renderContent = (range) => {
    return (
      <>
        {!range
          ? "Monthly"
          : `${range?.start?.format("LL")} - ${range?.end?.format("LL")}`}
      </>
    );
  };

  return (
    <div className="h-100 sc-overview">
      <div className="row align-items-stretch mb-4">
        <div className="col-md-6 col-lg-4 mb-2">
          <div className="wrap">
            <div className="row">
              <div className="col-8 text-start">
                <h6 className="variant-subtitle mb-4">Available Balance</h6>
                <h2 className="variant-title">
                  ${balance} {fundBalancePayload?.data?.primary_account_balance}
                </h2>
                <p className="variant-caption my-3">
                  Last updated{" "}
                  <span className="font-weight-bold">0 secs ago</span>
                </p>
                <button
                  className="btn btn-link btn-sm px-0 d-flex align-items-center variant-btn-text"
                  onClick={() => setShowAddFund(true)}>
                  Add Funds <i className="ms-1 ri-add-line"></i>
                </button>
              </div>
              <div className="col-4 d-flex align-item-start justify-content-end">
                {" "}
                <BalanceIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 mb-2">
          <div className="wrap">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h6 className="variant-subtitle">Plan Balance Left</h6>
              <h6 className="variant-subtitle2-primary">Basic</h6>
            </div>
            <div className="text-start">
              <h2 className="variant-title">
                $500<span className="variant-caption">/$1000</span>
              </h2>
              <div className="progress my-3">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "75%" }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"></div>
              </div>
              <button
                className="btn btn-link btn-sm px-0 d-flex align-items-center variant-btn-text"
                onClick={() => history.push(URLS.Settings)}>
                Go to plans <i className="ms-1 ri-arrow-right-s-line"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 mb-2">
          <div className="wrap text-start d-flex flex-column justify-content-between">
            <h6 className="variant-subtitle mb-4">Analytics</h6>

            <div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h6 className="variant-caption1">Customer Enrolled</h6>
                <h6 className=".variant-body1">300</h6>
              </div>

              <hr />

              <div className="d-flex align-items-center justify-content-between mt-3">
                <h6 className="variant-caption1">Business Enrolled</h6>
                <h6 className=".variant-body1">128</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-stretch">
        <div className="col-12">
          <EnrollmentChart
            loading={enrollmentsRateLoading}
            dataSource={enrollmentsRatePayload?.data.data}
            totalReceived={0}
            currencies={currencies?.map((v) => v.currency) || []}
            fetchingCurrencies={fetchingCurrencies}
            selectedCurrency={activeCurrency}
            onClear={() => {
              setRemount(true);
              onClearSelection();
            }}
            remount={remount}
            dateRange={dateRange}
            renderContent={renderContent}
            onSelect={onSelect}
            onCurrencyTabClick={(data) => setActiveCurrency(data)}
          />
        </div>
      </div>
      <AddFunds
        show={showAddFund}
        toggleModal={() => setShowAddFund(!showAddFund)}
      />
    </div>
  );
};

export default Overview;
