import React, { useEffect, useState } from "react";
import { LoadingTableContent } from "components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "shared/tablePagination/tablePagination";
import Table from "vgg-ui-react/dist/components/Core/Table";
import { Button } from "reactstrap";
import TransferModal from "./LinkModal/LinkModal";
// import { getTransfers } from "redux/actions/smartCheckInstance";
import _ from "lodash";
import { format } from "date-fns";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import EmptyState from "components/emptyState/EmptyState";

import "./links.scss";

const Links = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { getTransfersSuccess, isGettingTransfers } = useSelector(
    ({ smartCheck }) => smartCheck
  );
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);

  const applyFilter = () => {};

  const searchAccounts = _.debounce((e) => {
    // dispatch(getTransfers(currentPage, 10, e.target.value));
  }, 1000);

  useEffect(() => {
    // dispatch(getTransfers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sc-links table-wrapper">
      <div className="border-bottom p-3">
        <h4 className="page-title">Links</h4>
      </div>

      <div className=" px-3 pt-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="search-container">
            <input
              type="search"
              placeholder="search"
              className="form-control"
              data-testid="search-input"
              onChange={searchAccounts}
            />
            <i className="ri-search-line"> </i>
          </div>
          <div className="d-flex align-items-center">
            <FilterDropdown
              dropdownOpen={showFilterDropdown}
              onSubmit={applyFilter}
              disabled={true}
              toggleDropdown={() => setShowFilterDropdown(!showFilterDropdown)}
            />
            <Button
              color="primary"
              className="pg-action-btn ms-3"
              onClick={() => setShowTransferModal(true)}
            >
              New Link
            </Button>
            <TransferModal
              show={showTransferModal}
              toggleModal={() => setShowTransferModal(!showTransferModal)}
            />
          </div>
        </div>
        <Table cellVariants="default" className="overflow-auto table-striped">
          <thead>
            <tr>
              <th>Link ID</th>
              <th>Link</th>
              <th>Link Status</th>
              <th>Creation Date</th>
              <th></th>
            </tr>
          </thead>
          {isGettingTransfers ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={7} />
            </tbody>
          ) : (
            <tbody style={{ borderTop: "none" }}>
              {getTransfersSuccess?.data?.responseData?.items &&
                getTransfersSuccess?.data?.responseData?.items?.map((val) => (
                  <tr key={val?.id}>
                    <td>{val?.link_identifier || "--"}</td>
                    <td>{val?.link || "--"}</td>
                    <td>{val?.status || "--"}</td>
                    <td>
                      {val?.created_at
                        ? format(new Date(val.created_at), "dd/MM/YY")
                        : "--"}
                    </td>
                    <td></td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      </div>

      {(!isGettingTransfers &&
        getTransfersSuccess?.data?.responseData?.items?.length < 1) ||
      !getTransfersSuccess?.data?.responseData?.items ? (
        <EmptyState />
      ) : null}
      {getTransfersSuccess?.data?.responseData?.items &&
        getTransfersSuccess?.data?.responseData?.items?.length > 0 && (
          <div className="px-3">
            <TablePagination
              data={getTransfersSuccess?.data?.responseData?.items}
              totalCount={getTransfersSuccess?.data?.responseData?.count}
              currentPage={getTransfersSuccess?.data?.responseData?.pageNumber}
              pageSize={getTransfersSuccess?.data?.responseData?.pageSize}
              onPageChange={(page) => setCurrentPage(page)}
              totalPages={getTransfersSuccess?.data?.responseData?.pages}
            />
          </div>
        )}
    </div>
  );
};

export default Links;
