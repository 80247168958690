import React from "react";

const RowItem = ({ label, value }) => {
  return (
    <div className="py-3 row-item d-flex flex-column gap-2 border-bottom">
      <div className="sc-transaction-label">{label || "-"}</div>
      <div className="sc-transaction-value">{value || "-"}</div>
    </div>
  );
};

export default RowItem;
