import { userDetails } from "./user.service";

// https://afservicemanagerclienttest.azurewebsites.net
export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://smartcheck-api-test.azurewebsites.net/smartcheck"
    : `${window.env?.smartCheckBaseApiUrl}/smartcheck`;
export const kycUrl =
  process.env.NODE_ENV === "development"
    ? "https://vggkycservicetest.azurewebsites.net"
    : `${window.env?.kycBaseApiUrl}/api`;
export const serviceManager =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagerclienttest.azurewebsites.net/api"
    : `${window.env?.baseApiUrl}/api`;
export const accountService =
  process.env.NODE_ENV === "development"
    ? "https://accountserviceaggregator-apitest.azurewebsites.net/api"
    : `${window.env?.accountServiceApiUrl}/api`;

export const config = () => {
  if (userDetails()) {
    const bearerToken = `Bearer ${userDetails().accessToken}`;
    return { headers: { Authorization: bearerToken } };
  }
  return null;
};
