import React from "react";
import "./customSpinner.scss";

const CustomSpinner = ({ label, height }) => {
  return (
    <div style={{ height: height || "63vh" }} className="d-flex flex-column align-items-center justify-content-center custom-spinner">
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="24" stroke="#D7DCE0" stroke-width="2" />
        <mask id="path-2-inside-1_9667_10826" fill="white">
          <path d="M25 50C29.211 50 33.3537 48.9363 37.0438 46.9077C40.734 44.879 43.8518 41.9511 46.1082 38.3957C48.3646 34.8402 49.6863 30.7724 49.9507 26.5698C50.2151 22.3671 49.4136 18.1657 47.6207 14.3555C45.8277 10.5453 43.1014 7.24973 39.6946 4.77457C36.2879 2.29942 32.3111 0.724908 28.1333 0.197132C23.9556 -0.330643 19.7122 0.205423 15.7969 1.75559C11.8816 3.30575 8.42135 5.81979 5.73717 9.0644L7.08557 10.1799C9.58185 7.1624 12.7999 4.82435 16.4411 3.3827C20.0823 1.94104 24.0287 1.4425 27.914 1.93333C31.7993 2.42416 35.4977 3.88846 38.666 6.19035C41.8343 8.49225 44.3698 11.5571 46.0372 15.1006C47.7047 18.6441 48.45 22.5514 48.2041 26.4599C47.9582 30.3684 46.729 34.1514 44.6306 37.458C42.5322 40.7645 39.6326 43.4875 36.2008 45.3741C32.769 47.2608 28.9162 48.25 25 48.25V50Z" />
        </mask>
        <path
          d="M25 50C29.211 50 33.3537 48.9363 37.0438 46.9077C40.734 44.879 43.8518 41.9511 46.1082 38.3957C48.3646 34.8402 49.6863 30.7724 49.9507 26.5698C50.2151 22.3671 49.4136 18.1657 47.6207 14.3555C45.8277 10.5453 43.1014 7.24973 39.6946 4.77457C36.2879 2.29942 32.3111 0.724908 28.1333 0.197132C23.9556 -0.330643 19.7122 0.205423 15.7969 1.75559C11.8816 3.30575 8.42135 5.81979 5.73717 9.0644L7.08557 10.1799C9.58185 7.1624 12.7999 4.82435 16.4411 3.3827C20.0823 1.94104 24.0287 1.4425 27.914 1.93333C31.7993 2.42416 35.4977 3.88846 38.666 6.19035C41.8343 8.49225 44.3698 11.5571 46.0372 15.1006C47.7047 18.6441 48.45 22.5514 48.2041 26.4599C47.9582 30.3684 46.729 34.1514 44.6306 37.458C42.5322 40.7645 39.6326 43.4875 36.2008 45.3741C32.769 47.2608 28.9162 48.25 25 48.25V50Z"
          stroke="#175FFF"
          stroke-width="4"
          mask="url(#path-2-inside-1_9667_10826)"
        />
      </svg>
      <p className="waiting-text">{label || "Please wait..."}</p>
    </div>
  );
};

export default CustomSpinner;
