import React from "react";

const CTAButton = ({ title, current, onClick, ...res }) => {
  const getText = () => {
    if (title.toLowerCase() === "enterprise") return "Contact Support";
    if (current) return "Current Plan";
    return "Select Plan";
  };
  return (
    <div>
      <button
        className={`btn btn-${
          current ? "outline-" : ""
        }primary  btn-block  d-flex align-items-center justify-content-center font-weight-bold mb-4 gap-2`}
        onClick={onClick}
        {...res}
      >
        {getText()} {current && <i className="ri-check-line"></i>}
      </button>
    </div>
  );
};

export default CTAButton;
