import DocumentItem from "../../../CustomerEnrollment/CustomerInfo/DocumentItem/DocumentItem";
import React, { useState } from "react";
import "./businessDocument.scss";
import DocumentInfoModal from "pages/CustomerEnrollment/CustomerInfo/DocumentInfoModal/DocumentInfoModal";

const BusinessDocument = () => {
  const [viewDetails, setViewDetails] = useState(false);
  return (
    <div className="row px-3">
      <div className="col-md-4 col-lg-3">
        <DocumentItem onClick={() => setViewDetails(true)} />
      </div>
      <div className="col-md-4 col-lg-3">
        <DocumentItem onClick={() => setViewDetails(true)} />
      </div>

      <DocumentInfoModal
        show={viewDetails}
        toggleModal={() => setViewDetails(!viewDetails)}
      />
    </div>
  );
};

export default BusinessDocument;
