import React from "react";
import { Card, CardBody } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import "./components.scss";

const data = [
  {
    key: "wallet",
    title: "From your wallet",
  },
  {
    key: "bank",
    title: "Bank Transfer",
  },
];

const Initiate = ({ onSelectPayoutType, onClose }) => {
  return (
    <div className="fade_in">
      <div className="row mb-2">
        {data.map((v) => (
          <div key={v.key} className="col-12 mb-3">
            <Card
              onClick={() => onSelectPayoutType(v.key)}
              className="card-wrapper"
            >
              <CardBody>
                <div className="d-flex align-items-center justify-content-between">
                  <div>{v.title}</div>
                  <div>
                    <i className="ri-arrow-right-s-line .arrow"></i>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Initiate;
