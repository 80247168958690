import React, { useState } from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CustomTab from "components/CustomTab/CustomTab";
import BusinessLinkForm from "./BusinessLinkForm";
import CustomerLinkForm from "./CustomerLinkForm";
import "./linkModal.scss";

const tabItems = [
  {
    id: 1,
    title: "Customer",
    key: "customer-link-form",
    routeLink: "",
    content: <CustomerLinkForm />,
  },
  {
    id: 2,
    title: "Business",
    key: "business-link-form",
    routeLink: "",
    content: <BusinessLinkForm />,
  },
];

const LinkModal = ({ show, toggleModal }) => {
  const [activeTab, setActiveTab] = useState(tabItems[0].key);

  return (
    <div className="link-modal-wrapper">
      <Modal
        centered={true}
        fade={false}
        toggle={toggleModal}
        isOpen={show}
        backdrop="static"
      >
        <ModalHeader className="border-bottom-0" toggle={toggleModal}>
          Create new link
        </ModalHeader>
        <ModalBody>
          <CustomTab
            listClassNames="pb-0"
            useContent
            data={tabItems}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onTabItemClick={(item) => setActiveTab(item.key)}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LinkModal;
