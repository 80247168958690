import React, { useEffect } from "react";
import URLS from "helpers/urls";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";

import "./service-details.scss";
import TabContents from "./TabContents";
import CustomerInfo from "pages/CustomerEnrollment/CustomerInfo/CustomerInfo";
import BusinessInfo from "pages/BusinessEnrollment/BusinessInfo/BusinessInfo";
import BusinessMemberInfo from "pages/BusinessEnrollment/BusinessMemberInfo/BusinessMemberInfo";

const ServiceDetails = () => {
  const history = useHistory();

  useEffect(() => {
    // dispatch(fetchSupportedCurrencies());
    if (history.location.pathname === "/products/smart-check") {
      history.push(URLS.Overview);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="service-details mb-5">
      <Switch>
        <Route path="/products/smart-check/service" component={TabContents} />
        <Route exact path={URLS.CustomerInfo} component={CustomerInfo} />
        <Route
          exact
          path={URLS.BusinessMember}
          component={BusinessMemberInfo}
        />
        <Route path={URLS.BusinessInfo} component={BusinessInfo} />
      </Switch>
    </div>
  );
};

export default ServiceDetails;
