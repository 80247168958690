import BackButton from "components/BackButton/BackButton";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import URLS, { ViewBusinessInfo, ViewPeople } from "helpers/urls";
import DocumentInfoModal from "pages/CustomerEnrollment/CustomerInfo/DocumentInfoModal/DocumentInfoModal";
import DocumentItem from "pages/CustomerEnrollment/CustomerInfo/DocumentItem/DocumentItem";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PersonalInfo from "../BusinessInfo/PersonalInfo/PersonalInfo";
import "./businessMemberInfo.scss";

const breadcrumbItems = (name, id) => [
  {
    id: 1,
    name: "Business Enrollment",
    path: ViewBusinessInfo(id),
  },
  {
    id: 1,
    name: "People",
    path: ViewPeople(id),
  },
  {
    id: 2,
    name,
    path: "",
  },
];

const BusinessMemberInfo = () => {
  const history = useHistory();
  const params = useParams();
  const [viewDetails, setViewDetails] = useState(false);
  return (
    <div className="service-details customer-info mb-5">
      <BackButton
        label="Go Back"
        onClick={() => {
          const url = history.location.pathname.includes("customer")
            ? URLS.CustomerEnrollment
            : URLS.BusinessEnrollment;
          history.push(ViewPeople(params.id), { isBack: true });
        }}
      />

      <div className="my-3">
        <Breadcrumb items={breadcrumbItems("Adeboye Johnson")} />
      </div>

      <div className="table-wrapper customer-info">
        <div className="border-bottom p-3">
          <h4 className="page-title">Customer Information</h4>
        </div>
        <div className="row m-auto w-100">
          <div className="col-md-6 col-lg-4 border-r">
            <div className="p-3">
              <div className="sp-mb--24 sp-mt--24 d-flex align-items-center justify-content-center">
                <PersonalInfo />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-8 py-2">
            <div
              className="p-3"
              style={{ maxHeight: 630, overflowY: "auto", overflowX: "hidden" }}
            >
              <div className="row">
                <div className="col-md-6 mb-4">
                  <DocumentItem onClick={(data) => setViewDetails(true)} />
                </div>
                <div className="col-md-6 mb-4">
                  <DocumentItem onClick={(data) => setViewDetails(true)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DocumentInfoModal
        show={viewDetails}
        toggleModal={() => setViewDetails(!viewDetails)}
      />
    </div>
  );
};

export default BusinessMemberInfo;
