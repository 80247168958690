import Avatar from "components/Avatar/Avatar";
import "./personCard.scss";
import React from "react";

const PersonCard = ({ profileImg, firstName, email, designation, onClick }) => {
  return (
    <div className="card person-card" onClick={onClick}>
      <div className="card-body text-center d-flex flex-column align-items-center gap-3">
        <Avatar size={80} imageUrl={profileImg} name={firstName} />
        <div>
          <h5 className="card-title text-center">{firstName}</h5>
          <h6 className="card-subtitle mb-2 text-muted  text-center">
            {email}
          </h6>
          <p className="card-text text-center my-0">{designation}</p>
        </div>
      </div>
    </div>
  );
};

export default PersonCard;
