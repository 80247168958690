import { setUserDetails } from "apiServices/user.service";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { store } from "redux/store";
import App from "./App";
import "./index.scss";

window.renderSmartCheckServiceApp = ({
  containerId,
  history,
  userDetail,
  url,
}) => {
  setUserDetails({ ...userDetail });
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <App url={url} />
        </Router>
      </Suspense>
    </Provider>,
    document.getElementById(containerId)
  );
};

// unmount micro frontend function
window.unmountSmartCheckServiceApp = ({ containerId }) => {
  if (document.getElementById(containerId)) {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
  }
};

// Mount to root if it is not a micro frontend
if (!document.getElementById("SmartCheckServiceApp-container")) {
  if (document.getElementById("root")) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            <App baseRoute="/" />
          </Router>
        </Suspense>
      </Provider>,
      document.getElementById("root")
    );
  }
}
