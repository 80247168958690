import React, { useEffect, useState } from "react";
import CustomTab from "components/CustomTab/CustomTab";
import URLS from "helpers/urls";
import Overview from "pages/Overview/Overview";
import { Route, useHistory, Switch } from "react-router-dom";
import Settings from "pages/Settings/Settings";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupportedCurrencies } from "redux/actions/smartCheckInstance";
import Transactions from "pages/Transactions/Transactions";
import Links from "pages/Links/Links";
import CustomerEnrollment from "pages/CustomerEnrollment/CustomerEnrollment";
import BusinessEnrollment from "pages/BusinessEnrollment/BusinessEnrollment";
import "./service-details.scss";

const tabItems = [
  {
    id: 1,
    title: "Overview",
    key: "overview",
    routeLink: URLS.Overview,
    component: Overview,
  },
  {
    id: 2,
    title: "Transactions",
    key: "transactions",
    routeLink: URLS.Transactions,
    component: Transactions,
  },
  {
    id: 3,
    title: "Customer Enrollment",
    key: "customer-enrollment",
    routeLink: URLS.CustomerEnrollment,
    component: CustomerEnrollment,
  },
  {
    id: 4,
    title: "Business Enrollment",
    key: "business-enrollment",
    routeLink: URLS.BusinessEnrollment,
    component: BusinessEnrollment,
  },
  {
    id: 5,
    title: "Links",
    key: "links",
    // routeLink: [URLS.Links, URLS.LinkDetails],
    routeLink: URLS.Links,
    component: Links,
  },
  {
    id: 6,
    title: "Settings",
    key: "settings",
    routeLink: URLS.Settings,
    component: Settings,
  },
];

const TabContents = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(tabItems[0]?.key);
  const { currenciesPayload, fetchingCurrencies } = useSelector(
    ({ smartCheck }) => smartCheck
  );

  useEffect(() => {
    // dispatch(fetchSupportedCurrencies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePathChange = (path) => {
    switch (path) {
      case "/products/smart-check/service":
        history.push(URLS.Overview);
        break;
      case URLS.Overview:
        setActiveTab("overview");
        break;
      case URLS.Transactions:
        setActiveTab("transactions");
        break;
      case URLS.CustomerEnrollment:
        setActiveTab("customer-enrollment");
        break;
      case URLS.BusinessEnrollment:
        setActiveTab("business-enrollment");
        break;
      case URLS.Links:
        setActiveTab("links");
        break;
      case URLS.Settings:
        setActiveTab("settings");
        break;
      default:
        setActiveTab("overview");
    }
  };

  return (
    <div className="service-details mb-5">
      <section className="head-section d-flex">
        <div
          className="head-section__icon d-flex justify-content-center align-items-center"
          onClick={() => {
            window.location.replace("/products");
          }}
        >
          <i className="ri-arrow-drop-left-line"></i> Return to Dashboard
        </div>
      </section>

      <section
        className="content-section sp-pt--24"
        data-testid="content-section"
      >
        <CustomTab
          data={tabItems}
          onPathChange={handlePathChange}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        >
          <Switch>
            {tabItems.map(({ key, routeLink, component: Component }) => (
              <Route
                key={key}
                exact
                path={routeLink}
                render={() => (
                  <Component
                    currencies={currenciesPayload?.data?.data || []}
                    fetchingCurrencies={fetchingCurrencies}
                  />
                )}
              />
            ))}
          </Switch>
        </CustomTab>
      </section>
    </div>
  );
};

export default TabContents;
