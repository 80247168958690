import { getUserDetails } from "apiServices/user.service";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import {
  checkOnboardingStatus,
  requestAccess,
} from "redux/actions/smartCheckInstance";
import "./onboardingPage.scss";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import AlertModal from "components/AlertModal/AlertModal";

function OnboardingPage({ isApproved, onOnboardClicked, merchantId }) {
  const dispatch = useDispatch();
  const { requestingAccess, requestAccessPayload, requestAccessError } =
    useSelector(({ smartCheck }) => smartCheck);

  const { Client_Identifier } = getUserDetails()?.accessToken
    ? jwt_decode(getUserDetails()?.accessToken)
    : {};

  const [showConfirm, setShowConfirm] = useState(false);
  const [message, setMessage] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleConfirm = (confirmed) => {
    setShowConfirm(false);
    if (confirmed) {
      setSubmitStatus(true);
      dispatch(requestAccess(Client_Identifier, getUserDetails()?.user.email));
    }
  };

  const handleClose = () => {
    setMessage(false);
    dispatch(checkOnboardingStatus(merchantId));
  };

  const handleRequestAccess = () => {
    onOnboardClicked();
    setShowConfirm(true);
  };

  // const handleRequestAccess = () => {
  //   swal({
  //     text: "This action will onboard you into the smartcheck service. Click OKAY if you agree with the terms and conditions of the service.",
  //     icon: "info",
  //     button: "Okay",
  //     isLoader: requestingAccess,
  //   }).then(() => {
  //     if (!requestAccessPayload?.data?.hasError) {
  //       setSubmitStatus(true);
  //       dispatch(
  //         requestAccess(Client_Identifier, getUserDetails()?.user.email)
  //       );
  //     }
  //   });
  // };

  // useEffect(() => {
  //   if (
  //     !requestingAccess &&
  //     requestAccessPayload?.data.requestSuccessful &&
  //     submitStatus
  //   ) {
  //     setSubmitStatus(false);
  //     swal({
  //       text: requestAccessPayload?.data.requestSuccessful
  //         ? "You'll receive a status update notification via email within 2 business days."
  //         : "Result",
  //       html: true,
  //       content: renderAlertContent(requestAccessPayload),
  //       icon: "info",
  //       button: "Okay",
  //     }).then(() => {
  //       if (requestAccessPayload?.data.requestSuccessful) {
  //         setDisabled(true);
  //       }
  //     });
  //   }
  //   if (
  //     !requestingAccess &&
  //     !requestAccessPayload?.data.requestSuccessful &&
  //     submitStatus
  //   ) {
  //     setSubmitStatus(false);
  //     swal({
  //       text: requestAccessPayload?.data.Message,
  //       icon: "error",
  //       button: "Okay",
  //     });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [requestingAccess]);

  useEffect(() => {
    if (
      !requestingAccess &&
      (requestAccessPayload || requestAccessError) &&
      submitStatus
    ) {
      setSubmitStatus(false);
      if (!requestAccessPayload?.data?.hasError) {
        setDisabled(true);
      }
      setMessage(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestingAccess]);
  const renderAlertContent = (data) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div
          className={`font-weight-bold f-11 text-${
            data?.requestSuccessful ? "success" : "danger"
          }`}
        >
          {data?.requestSuccessful
            ? `Your onboarding ${
                data?.responseData?.providerName
                  ? "on" + data?.responseData?.providerName
                  : ""
              } has been initiated.`
            : data?.responseMessage ||
              "Something went wrong while trying to onboard merchant"}
        </div>
        <div className="msg-wrapper">
          <i
            className={`ri-${
              data?.requestSuccessful
                ? "check-line text-success"
                : "close-line text-danger"
            }`}
          ></i>
        </div>
      </div>
    );
  };

  return (
    <div className="onboard-wrapper">
      <section className="head-section d-flex sp-mb--24">
        <div
          className="head-section__icon d-flex justify-content-center align-items-center"
          onClick={() => {
            window.location.replace("/products");
          }}
        >
          <i className="ri-arrow-drop-left-line"></i> SmartCheck
        </div>
      </section>
      <section className="card-section sp-pt--24 sp-pb--24 sp-pl--32 sp-pr--32">
        <div className="sp-mb--16">
          <Typography typographyVariant="section-title" text="SmartCheck" />
        </div>
        <div className="w-sm-100 w-75">
          <p className="content-paragraph">
            SmartCheck is the smart way to onboard your customers with full
            compliance. With no-code enrollment link, you can onboard customers
            without writing a single line of code. Get started with SmartCheck
            for simple, fast and compliant experience for your customers.
          </p>
        </div>
        <div>
          <Button
            label={requestingAccess ? "Requesting..." : "Request Access"}
            background="blue"
            disabled={isApproved || disabled || requestingAccess}
            size="md"
            className="onboard-btn"
            onClick={handleRequestAccess}
          />

          <Button
            label={
              <span className="d-flex align-items-center">
                <span>Learn More</span>
                <i className="ri-arrow-right-up-line"></i>
              </span>
            }
            onClick={() =>
              window.open("https://developer.baseone.co/docs/kyc/", "_blank")
            }
            background="neutral"
            size="md"
            className="learn-more-btn"
          />
        </div>
        <p className="footnote">
          Note: BaseOne empowers you to unlimited smartcheck accounts for your
          users and customers.
        </p>
      </section>

      <ConfirmationModal show={showConfirm} onConfirm={handleConfirm} />
      <AlertModal
        show={message}
        data={requestAccessPayload}
        onCancel={handleClose}
      />
    </div>
  );
}

export default OnboardingPage;
