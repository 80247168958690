import React from "react";
import "./avatar.scss";

const Avatar = ({ imageUrl, altText, size, name }) => {
  function getInitials(fn) {
    const names = fn.split(" ");
    if (names.length === 1) {
      const initials = names.map(
        (name) => `${name.charAt(0)}${name.charAt(1) || ""}`
      );
      return initials.join("")?.toUpperCase();
    }
    const initials = names.map((name) => name.charAt(0));
    return initials.join("")?.toUpperCase();
  }

  return (
    <div
      className="d-flex align-items-center justify-content-center avatar"
      style={{
        width: size,
        height: size,
      }}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={altText || "profile"}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      ) : (
        getInitials(name)
      )}
    </div>
  );
};

export default Avatar;
