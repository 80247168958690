import React from "react";

const RowItem = ({ label, value }) => {
  return (
    <div className="py-2 px-3 person-row-item d-flex flex-column gap-2">
      <div className="sc-person-label">{label || "-"}</div>
      <div className="sc-person-value">{value || "-"}</div>
    </div>
  );
};

export default RowItem;
