import React, { useEffect, useState } from "react";
import { LoadingTableContent } from "components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
import TablePagination from "shared/tablePagination/tablePagination";
import Table from "vgg-ui-react/dist/components/Core/Table";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import EmptyState from "components/emptyState/EmptyState";

import "./businessEnrollment.scss";
import { Button } from "reactstrap";
import { ViewBusinessInfo } from "helpers/urls";
import { useHistory } from "react-router-dom";
import NewBusiness from "./NewBusiness/NewBusiness";

const BusinessEnrollment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { businesses, fetchingBusinesses } = useSelector(
    ({ smartCheck }) => smartCheck
  );
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    // dispatch(fetchTransactions({ ...page }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSearch = _debounce((event) => {
    const search = event.target.value;
    // dispatch(fetchTransactions({ ...page, search }));
  }, 1000);

  return (
    <div className="table-wrapper">
      <div className="border-bottom p-3">
        <h4 className="page-title">Transactions</h4>
      </div>
      <div className="px-3 pt-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search"
              className="form-control"
              onChange={handleSearch}
            />
            <i className="ri-search-line"></i>
          </div>

          <div className="d-flex align-items-center gap-3">
            <FilterDropdown
              dropdownOpen={false}
              onSubmit={() => {}}
              disabled={true}
              toggleDropdown={() => {}}
            />
            <Button
              color="primary"
              className="pg-action-btn"
              onClick={() => setShowForm(true)}
            >
              New Business
            </Button>
          </div>
        </div>
        <Table cellVariants="default" className="table-striped">
          <thead>
            <tr>
              <th width="20%">Business Name</th>
              <th width="20%">Business ID</th>
              <th width="20%">Country</th>
              <th width="15%">Status</th>
              <th width="15%">Enrollment Date</th>
              <th width="10%"></th>
            </tr>
          </thead>
          {fetchingBusinesses ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={6} />
            </tbody>
          ) : (
            <tbody style={{ borderTop: "none" }}>
              {businesses?.data &&
                businesses?.data?.map((val) => (
                  <tr key={val?.id}>
                    <td>
                      {val?.business_name || "--"}
                      <span className="fw-normal"></span>
                    </td>
                    <td>{val?.business_identifier || "--"}</td>
                    <td>{val?.country || "--"}</td>
                    <td>{val?.status || "--"}</td>
                    <td>{val?.created_at || "--"}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <button
                          className="btn btn-link btn-sm px-0 d-flex align-items-center variant-btn-text-primary"
                          onClick={() => history.push(ViewBusinessInfo(val.id))}
                        >
                          View
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      </div>
      {(!fetchingBusinesses && businesses?.data?.length < 1) ||
      !businesses?.data ? (
        <EmptyState />
      ) : null}
      {businesses?.data && businesses?.data?.length > 0 && (
        <div className="px-3">
          <TablePagination
            data={businesses?.data}
            totalCount={businesses?.count}
            currentPage={businesses?.page_number}
            pageSize={businesses?.page_size}
            onPageChange={(page) =>
              setPage((prev) => ({ ...prev, page_number: page }))
            }
            totalPages={businesses?.pages}
          />
        </div>
      )}

      <NewBusiness show={showForm} toggleModal={() => setShowForm(!showForm)} />
    </div>
  );
};

export default BusinessEnrollment;
