export const URLS = {
  Home: "/products/smart-check",
  Overview: "/products/smart-check/service/overview",
  Transactions: "/products/smart-check/service/transactions",
  CustomerEnrollment: "/products/smart-check/service/customer-enrollment",
  BusinessEnrollment: "/products/smart-check/service/business-enrollment",
  Settings: "/products/smart-check/service/settings",
  Links: "/products/smart-check/service/links",
  CustomerInfo:
    "/products/smart-check/customer-enrollment/customer-information/:id",
  BusinessInfo:
    "/products/smart-check/business-enrollment/business-information/:id",
  BusinessDocument:
    "/products/smart-check/business-enrollment/business-information/:id/business-document",
  People:
    "/products/smart-check/business-enrollment/business-information/:id/people",
  BusinessMember:
    "/products/smart-check/business-enrollment/business-information/:id/people/:personId",

  SmartCheckService: "/products/smart-check/services",
  ConfigureUpdate: "/products/smart-check/configure-smart-check/:id",
  Configuration: "/products/smart-check/configuration/:id",
  Configure: "/products/smart-check/configure-smart-check",
  Lookup: "/products/smart-check/lookup",
  LookupDetails: "/products/smart-check/lookup/:lookupType",
  SmartCheckInstances: "/products/smart-check/instances",
};

export const ViewCustomerInfo = (id) => {
  return URLS.CustomerInfo.replace(":id", id);
};

export const ViewBusinessInfo = (id) => {
  return URLS.BusinessInfo.replace(":id", id);
};

export const ViewBusinessDocument = (id) => {
  return URLS.BusinessDocument.replace(":id", id);
};

export const ViewPeople = (id) => {
  return URLS.People.replace(":id", id);
};

export const ViewBusinessMemberInfo = (id, personId) => {
  const newUrl = URLS.BusinessMember.replace(":id", id);
  console.log(newUrl);
  return newUrl.replace(":personId", personId);
};

export default URLS;
