import React, { useEffect, useState } from "react";
import "./personalInfo.scss";
import RowItem from "./RowItem";

const PersonalInfo = (data) => {
  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (data) {
      setDetails(data.data);
    }
  }, [data]);
  return (
    <div className="w-100 row sc-personal-info">
      <div className="col-12 mb-4">
        <div className="d-flex align-items-center justify-content-center flex-column text-center gap-3">
          <img
            src={null}
            className="rounded-circle"
            alt="customer avatar"
            height={100}
            width={100}
          />

          <div>
            <p className="person-name">
              {details?.FirstName !== "" && details?.FirstName
                ? details.FirstName
                : "_"}{" "}
              {details?.Lastname !== "" && details?.Lastname
                ? details?.Lastname
                : "_"}
            </p>
          </div>
        </div>
      </div>
      details
      <div className="col-12 mb-5">
        <div className="d-flex flex-column sc-personal-details">
          <RowItem label="Email" value={details?.Email || "_"} />
          <RowItem label="Customer ID" value={details?.CustomerID || "_"} />
          <RowItem label="Country" value={details?.Country || "_"} />
          <RowItem
            label="Enrollment Date"
            value={
              new Date(
                details?.EnrolmentDate || new Date().toDateString()
              ).toDateString() || "_"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
