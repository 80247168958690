import React from "react";
import "./personalInfo.scss";
import RowItem from "./RowItem";

const PersonalInfo = () => {
  return (
    <div className="w-100 row sc-personal-info">
      <div className="col-12 mb-4">
        <div className="d-flex align-items-center justify-content-center flex-column text-center gap-3">
          <img
            src="https://newprofilepic2.photo-cdn.net//assets/images/article/profile.jpg"
            className="rounded-circle"
            alt=""
            height={100}
            width={100}
          />

          <div>
            <p className="person-name">Adeboye Johnson</p>
          </div>
        </div>
      </div>
      <div className="col-12 mb-5">
        <div className="d-flex flex-column gap-3">
          <RowItem label="Email" value="jamesadettutun@mail.com" />
          <RowItem label="Customer ID" value="09390232031" />
          <RowItem label="Country" value="Nigeria" />
          <RowItem label="Enrollment Date" value="12/08/21" />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
