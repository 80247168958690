import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import SelectControl from "components/selectControl/SelectControl";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { Spinner } from "reactstrap";

const validationSchema = Yup.object().shape({
  business_name: Yup.string().required("Business Name is required!"),
  id_type: Yup.string().required("ID Type is required!"),
});

const defaultValues = {
  business_name: "",
  id_type: "",
  description: "",
};

const BusinessLinkForm = ({ submitForm, processing }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  return (
    <form onSubmit={handleSubmit(submitForm)} style={{ minHeight: 300 }}>
      <div className="d-flex flex-column gap-3">
        <div className="">
          <label htmlFor="business_name" className="form-label">
            Business Name
          </label>
          <Controller
            control={control}
            name="business_name"
            id="business_name"
            render={({ field: { onChange, onBlur, ref, value, ...res } }) => (
              <Select
                {...register("business_name", { required: true })}
                onBlur={onBlur}
                onChange={(e) => onChange({ target: e })}
                inputRef={ref}
                controlClassName={`form-control ${
                  errors?.business_name ? "is-invalid" : ""
                } ps-0 pt-0 pb-0`}
                controlErrorMsg={errors?.business_name?.message}
                components={{
                  IndicatorSeparator: () => null,
                  Control: SelectControl,
                }}
                defaultValue={value}
                options={[]}
              />
            )}
          />
          {/* <div className="invalid-feedback">
            {errors.business_name?.message}
          </div> */}
        </div>

        <div className="">
          <label htmlFor="id_type" className="form-label">
            ID Type
          </label>
          <Controller
            control={control}
            name="id_type"
            id="id_type"
            render={({ field: { onChange, onBlur, ref, value, ...res } }) => (
              <Select
                {...register("id_type", { required: true })}
                onBlur={onBlur}
                onChange={(e) => onChange({ target: e })}
                inputRef={ref}
                controlClassName={`form-control ${
                  errors?.id_type ? "is-invalid" : ""
                } ps-0 pt-0 pb-0`}
                controlErrorMsg={errors?.id_type?.message}
                components={{
                  IndicatorSeparator: () => null,
                  Control: SelectControl,
                }}
                defaultValue={value}
                options={[]}
              />
            )}
          />
          {/* <div className="invalid-feedback">{errors.id_type?.message}</div> */}
        </div>

        <div className="">
          <label htmlFor="business_name" className="form-label">
            Description
          </label>
          <textarea
            rows="8"
            style={{ minHeight: "100px" }}
            className="form-control"
            {...register("description", { required: false })}
          ></textarea>
        </div>

        <div className="">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="useMultipleTimes"
            />
            <label className="form-check-label" for="useMultipleTimes">
              Use multiple times
            </label>
          </div>
        </div>

        <div>
          <Button
            type="submit"
            label={
              processing ? (
                <Spinner className="me-1" size="sm" color="light"></Spinner>
              ) : (
                <>{processing ? "Creating..." : "Create"}</>
              )
            }
            background="blue"
            className="w-100"
            disabled={processing}
          />
        </div>
      </div>
    </form>
  );
};

export default BusinessLinkForm;
