import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import SelectControl from "components/selectControl/SelectControl";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { Spinner } from "reactstrap";

const validationSchema = Yup.object().shape({
  business_name: Yup.string().required("Business Name is required!"),
  business_identifier: Yup.string().required("Business ID is required!"),
  country: Yup.string().required("Country is required!"),
});

const defaultValues = {
  business_name: "",
  business_identifier: "",
  country: "",
};

const NewBusinessForm = ({ submitForm, processing }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  return (
    <form onSubmit={handleSubmit(submitForm)} style={{ minHeight: 300 }}>
      <div className="d-flex flex-column gap-3">
        <div className="">
          <label htmlFor="business_name" className="form-label">
            Business Name
          </label>
          <input
            id="business_name"
            className={`form-control ${
              errors?.business_name ? "is-invalid" : ""
            } ps-0 pt-0 pb-0`}
            {...register("business_name", { required: true })}
          ></input>
          <div className="invalid-feedback">
            {errors.business_name?.message}
          </div>
        </div>

        <div className="">
          <label htmlFor="country" className="form-label">
            Country
          </label>
          <Controller
            control={control}
            name="country"
            id="country"
            render={({ field: { onChange, onBlur, ref, value, ...res } }) => (
              <Select
                {...register("country", { required: true })}
                onBlur={onBlur}
                onChange={(e) => onChange({ target: e })}
                inputRef={ref}
                controlClassName={`form-control ${
                  errors?.country ? "is-invalid" : ""
                } ps-0 pt-0 pb-0`}
                controlErrorMsg={errors?.country?.message}
                components={{
                  IndicatorSeparator: () => null,
                  Control: SelectControl,
                }}
                defaultValue={value}
                options={[]}
              />
            )}
          />
          <div className="invalid-feedback">{errors.country?.message}</div>
        </div>

        <div className="">
          <label htmlFor="business_identifier" className="form-label">
            Busness ID
          </label>
          <input
            id="business_identifier"
            className={`form-control ${
              errors?.business_identifier ? "is-invalid" : ""
            } ps-0 pt-0 pb-0`}
            {...register("business_identifier", { required: true })}
          ></input>
          <div className="invalid-feedback">
            {errors.business_identifier?.message}
          </div>
        </div>

        <div>
          <Button
            type="submit"
            label={
              processing ? (
                <Spinner className="me-1" size="sm" color="light"></Spinner>
              ) : (
                <>{processing ? "Creating..." : "Create"}</>
              )
            }
            background="blue"
            className="w-100"
            disabled={processing}
          />
        </div>
      </div>
    </form>
  );
};

export default NewBusinessForm;
