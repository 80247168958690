import React from "react";
import noData from "assets/images/noData.svg";
import "./emptyState.scss";
import { Button, Typography } from "vgg-ui-react/dist/components/Core";

const EmptyState = ({ history, description }) => {
  return (
    <div className="empty-container">
      <div>
        <div className="sp-mb--24">
          <img src={noData} alt="empty" />
        </div>
        <div className="text-center sp-mb--16">
          <div className="sp-mb--8">
            <Typography
              typographyVariant="subtitle1"
              text="Nothing here"
              className="font-weight-bold"
            />
          </div>
          <div>
            <Typography
              typographyVariant="caption"
              text={description}
              className="sp-text-color--neutral-400"
            />
          </div>
        </div>
        {history && (
          <div className="text-center">
            <Button
              label="Configure"
              background="blue"
              onClick={() => history.push("/products/smart-check")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
