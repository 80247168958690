import React from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import document from "assets/images/doc-large.png";
import RowItem from "../PersonalInfo/RowItem";
import "./documentInfoModal.scss";

const DocumentInfoModal = ({ show, toggleModal }) => {
  return (
    <div className="customer-info-modal-wrapper">
      <Modal
        centered={true}
        fade={false}
        toggle={toggleModal}
        isOpen={show}
        backdrop="static"
      >
        <ModalHeader className="border-bottom-0" toggle={toggleModal}>
          Passport
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-3">
            <div>
              <img
                src={document}
                alt="doc"
                height={388}
                className="rounded w-100"
              />
            </div>
            <RowItem label="Passport Number" value="09390232031" />
            <RowItem label="Serial Number" value="09390232031" />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DocumentInfoModal;
