import React, { useState } from "react";
import Select from "react-select";
import CurrencyFlag from "react-currency-flags";
import "./currencySelect.scss";
import SelectControl from "components/selectControl/SelectControl";

const CurrencySelect = ({ data, onSelect, placeholder, label, ...res }) => {
  const [showCheck, setShowCheck] = useState(false);
  const renderRow = (currency) => {
    return (
      <div
        className="d-flex align-items-center justify-content-between"
        onMouseEnter={(e) => {
          setShowCheck(e.target.id);
        }}
        id={currency}
      >
        <div className="d-flex align-items-center">
          <CurrencyFlag
            currency={currency}
            size="sm"
            className="currency-row"
          />{" "}
          {currency}
        </div>
        {showCheck === currency && (
          <div>
            <i className="ri-check-line text-primary"></i>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="d-flex flex-column w-100">
      <label className="selecte-label">{label}</label>
      <Select
        {...res}
        options={data?.map((item) => ({
          ...item,
          label: renderRow(item.label),
        }))}
        components={{
          IndicatorSeparator: () => null,
          Control: SelectControl,
        }}
        con
        onChange={onSelect}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CurrencySelect;
