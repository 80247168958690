import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";
import ServiceDetails from "pages/ServiceDetails/ServiceDetails";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import CustomSpinner from "shared/CustomSpinner/CustomSpinner";
import { checkOnboardingStatus } from "redux/actions/smartCheckInstance";
import { errorMessage, successMessage } from "redux/actions/notification";
import { getUserDetails } from "apiServices/user.service";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import AwaitingApprovalPage from "pages/AwaitingApprovalPage/AwaitingApprovalPage";
const ManagerPage = () => {
  const dispatch = useDispatch();
  const { checkingMerchantStatus, merchantStatus, merchantStatusError } =
    useSelector(({ smartCheck }) => smartCheck);

  const { Client_Identifier } = getUserDetails()?.accessToken
    ? jwt_decode(getUserDetails()?.accessToken)
    : {};

  const [checked, setChecked] = useState(true);
  const [alreadyApproved, setAlreadyApproved] = useState(true);

  useEffect(() => {
    // Check onboarding status on component mount
    dispatch(checkOnboardingStatus(Client_Identifier));
  }, [dispatch, Client_Identifier]);

  useEffect(() => {
    if (!checkingMerchantStatus && merchantStatus && checked) {
      setChecked(false);
      !alreadyApproved &&
        dispatch(
          successMessage(
            merchantStatus?.data?.responseMessage || `Verification complete. `
          )
        );
    }
    if (!checkingMerchantStatus && merchantStatusError && checked) {
      setChecked(false);
      dispatch(
        errorMessage(
          merchantStatusError?.message ||
            "Unable to verify your onboarding status on this service"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkingMerchantStatus, merchantStatusError, merchantStatus]);

  if (checkingMerchantStatus) {
    return <CustomSpinner height="75vh" />;
  }

  if (merchantStatus?.data?.requestSuccessful) {
    const isPending = merchantStatus?.data?.OnboardingStatus === "Pending";
    const isApproved = merchantStatus?.data?.OnboardingStatus === "Approved";

    if (isPending) return <AwaitingApprovalPage />;
    if (isApproved) return <ServiceDetails />;
    return (
      <OnboardingPage
        isApproved={isApproved}
        onOnboardClicked={() => setAlreadyApproved(false)}
        merchantId={Client_Identifier}
      />
    );
  }

  return merchantStatusError && <ErrorPage />;
};

export default ManagerPage;
