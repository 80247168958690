import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { errorMessage, successMessage } from "redux/actions/notification";
import Initiate from "./components/Initiate";
import TransferForm from "./components/TransferForm";
import CustomSpinner from "shared/CustomSpinner/CustomSpinner";
import "./addFunds.scss";
import {
  fetchSupportedCurrencies,
  makeTransfer,
} from "redux/actions/smartCheckInstance";
import AccountDetails from "./components/AccountDetails";

const MODAL_SCREENS = {
  initiate: {
    title: "Add Funds",
    channel: 0,
  },
  wallet: {
    title: "From your wallet",
    channel: 1,
  },
  bank: {
    title: "Bank Transfer",
    channel: 2,
  },
};

const AddFunds = ({ show, toggleModal }) => {
  const dispatch = useDispatch();
  const [submitStatus, setSubmitStatus] = useState(null);
  const [fetchedBank, setFetchedBank] = useState(null);
  const [waiting, setWaitng] = useState(true);
  const [activeScreen, setActiveScreen] = useState(MODAL_SCREENS.initiate);
  const { title, channel } = useMemo(() => activeScreen, [activeScreen]);

  const {
    fetchingCurrencies,
    currenciesPayload,
    transferPayload,
    transferPayloadError,
    makingTransfer,
    supportedCountries,
    banksPayload,
    banksPayloadError,
    loadingBanks,
    resolveBankAccountLoading,
    resolveBankAccountSuccess,
    resolveBankAccountError,
  } = useSelector(({ smartCheck }) => smartCheck);

  useEffect(() => {
    dispatch(fetchSupportedCurrencies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingBanks && fetchedBank) {
      if (banksPayload) {
        if (!banksPayload?.requestSuccessful) {
          dispatch(
            errorMessage(
              banksPayload?.message ||
                `Unable load ${
                  activeScreen.channel === 1 ? "Banks" : "Mobile Operators"
                }`
            )
          );
        }
      }
      if (banksPayloadError) {
        dispatch(
          errorMessage(
            banksPayloadError?.message ||
              `Unable load ${
                activeScreen.channel === 1
                  ? "Fund from Wallet"
                  : "Bank Transfer"
              }`
          )
        );
      }
      setFetchedBank(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banksPayload, loadingBanks, banksPayloadError, setFetchedBank]);

  useEffect(() => {
    if (!makingTransfer && submitStatus) {
      if (transferPayload) {
        if (transferPayload?.requestSuccessful) {
          setActiveScreen(MODAL_SCREENS.initiate);
          dispatch(
            successMessage(transferPayload?.message || "Transfer successful")
          );
        } else {
          dispatch(
            errorMessage(transferPayload?.message || "Unable to make tranfer")
          );
        }
      }
      if (transferPayloadError) {
        dispatch(
          errorMessage(transferPayloadError?.message || "Unable to update data")
        );
      }
      setSubmitStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferPayload, makingTransfer, transferPayloadError, setSubmitStatus]);

  useEffect(() => {
    if (waiting) {
      setTimeout(() => {
        setWaitng(false);
      }, 500);
    }
  }, [waiting]);

  const submitForm = (payload) => {
    setSubmitStatus(true);
    dispatch(makeTransfer({ payload, channel }));
  };

  const renderContent = useCallback(() => {
    if (channel === 1) {
      return (
        <TransferForm
          sending={makingTransfer}
          submitForm={submitForm}
          loadingWallets={fetchingCurrencies}
          walletOptions={
            currenciesPayload?.data?.data?.map((v) => ({
              label: v.currency,
              value: v.currency,
            })) || []
          }
          channel={channel}
        />
      );
    }
    if (channel === 2) {
      return <AccountDetails loading={false} />;
    }

    return (
      <Initiate
        onSelectPayoutType={(type) => {
          setWaitng(true);
          setActiveScreen(MODAL_SCREENS[type]);
        }}
        onClose={toggleModal}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    banksPayload?.data,
    banksPayload?.responseData,
    channel,
    loadingBanks,
    fetchingCurrencies,
    makingTransfer,
    resolveBankAccountError,
    resolveBankAccountLoading,
    resolveBankAccountSuccess?.responseData,
    supportedCountries?.responseData,
    currenciesPayload?.data,
  ]);

  return (
    <Modal isOpen={show} centered className="payout-modal" backdrop="static">
      <ModalHeader
        toggle={() => {
          if (show) setActiveScreen(MODAL_SCREENS.initiate);
          toggleModal();
        }}
      >
        <div className="d-flex align-items-center">
          {channel > 0 && (
            <i
              className="ri-arrow-left-line me-2 pointer"
              onClick={() => setActiveScreen(MODAL_SCREENS.initiate)}
            ></i>
          )}
          {title}
        </div>
      </ModalHeader>
      <ModalBody>{waiting ? <CustomSpinner /> : renderContent()}</ModalBody>
      {channel === 2 ? (
        <ModalFooter className="d-flex flex-column mx-2 no-bt">
          <div className="note mb-3 w-100">
            <i className="ri-error-warning-line"></i>
            <p className="note-content">
              Bank charges may apply for this transaction
            </p>
          </div>
          <Button
            type="button"
            className="w-100"
            label="Close"
            background="blue"
            size="md"
            onClick={() => {
              toggleModal();
              setActiveScreen(MODAL_SCREENS.initiate);
            }}
            style={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
            }}
          />
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

export default AddFunds;
