import React from "react";
import { Link } from "react-router-dom";
import "./breadcrumb.scss";

const Breadcrumb = ({ items }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb sc-breadcrumb">
        <li className="breadcrumb-item service" aria-current="page">
          SmartCheck
        </li>
        {items.map((v, idx) => (
          <li className="breadcrumb-item active" aria-current="page">
            {idx === items.length - 1 ? (
              v.name
            ) : (
              <Link to={v.path}>{v.name}</Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
