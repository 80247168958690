import Drawer from "components/Drawer/Drawer";
import React from "react";
import RowItem from "./RowItem";
import "./viewTransaction.scss";

const ViewTransaction = ({ open, toggle, data }) => {
  return (
    <Drawer title="Transactions" open={open} toggle={toggle}>
      <div className="d-flex flex-column view-transaction">
        <RowItem label="Transaction Reference" value={data?.TransactionRef} />
        <RowItem label="Amount" value={data?.Amount} />
        <RowItem label="New Balance" value={data?.new_balance} />
        <RowItem label="Account" value={data?.AccountNumber} />
        <RowItem label="Status" value={data?.Status} />
        <RowItem label="Narration" value="Payment for March" />
        <RowItem
          label="Date"
          value={
            data?.CreatedAt ? new Date(data?.CreatedAt).toDateString() : null
          }
        />
      </div>
    </Drawer>
  );
};

export default ViewTransaction;
