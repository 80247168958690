import React, { useState } from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import NewBusinessForm from "./NewBusinessForm";

const NewBusiness = ({ show, toggleModal }) => {
  return (
    <div className="link-modal-wrapper">
      <Modal
        centered={true}
        fade={false}
        toggle={toggleModal}
        isOpen={show}
        backdrop="static"
      >
        <ModalHeader className="border-bottom-0" toggle={toggleModal}>
          New Business
        </ModalHeader>
        <ModalBody>
          <NewBusinessForm />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NewBusiness;
